import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GET_BOOKING_LIST_V2, GET_BOOKING_LIST_V2_EMPTY } from '../../../../../store/actions';
import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { Constant } from '../../../../Helpers/constant';

const Query = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [bookingType, setBookingType] = useState(['DELAYED']);
  const [loading, setLoading] = useState(false);
  const [updateType, setUpdateType] = useState('');
  const [isDetailsPage, setIsDetailsPage] = useState(false);

  console.log('bookingDetails', isShowConfirmationModal);

  /* ---------------------------- REDUX STATES ---------------------------- */

  /* ---------------------------- DISPATCHING ---------------------------- */

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Query`) {
      setIsDetailsPage(false);
    }
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(1);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_LIST_V2,
        payload: {
          data: {
            booking_type: bookingType,
            page: 0,
            page_size: Constant.TABLE_PAGE_SIZE,
            sort_direction: 'DESC',
            ...filters,
          },
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- FILTER RESET HANDLER ---------------------------- */
  const onResetHandler = () => {
    setCurrentPageNumber(1);

    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: 0,
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_direction: 'DESC',
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber + 1);

    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: pageNumber + 1,
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_by: currentlyAppliedFilters?.sort_by ?? 'updated_at',
          sort_direction: 'DESC',
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);
    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(!isDetailsPage);
    }
  };

  useEffect(() => {
    let a = false;
    if (a) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading && <Loader zIndex={true} />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {' '}
          <BreadCrumb title="Query" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Query`]} />
          <Filter data={{}} filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} onReset={onResetHandler} />
          <Table
            // data={bookings?.result}
            // totalData={bookings?.total_count}
            pageNumber={currentPageNumber}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            appliedFilters={currentlyAppliedFilters}
          />
        </Container>
      </div>
    </>
  );
};

export default Query;
