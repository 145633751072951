import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Button, Modal, ModalBody, Col, Label, Input, Row } from 'reactstrap';

import { Error } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

const UpdateBookingEnbd = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    dropLocation: {
      airportId: '',
      nickName: '',
      name: '',
      address: '',
      emirates: '',
      region: '',
      regionId: '',
      zipCode: '',
      latitude: '',
      longitude: '',
      landmark: '',
      mobileNumber: '',
      landlineNumber: '',
    },
    pickupLocation: {
      airportId: '',
      nickName: '',
      name: '',
      address: '',
      emirates: '',
      region: '',
      regionId: '',
      zipCode: '',
      latitude: '',
      longitude: '',
      landmark: '',
      mobileNumber: '',
      landlineNumber: '',
    },
    dateTimeOfService: {
      date: '',
      time: '',
    },
    // bookingDateTime: '',
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    setState({
      dropLocation: data?.dropLocation,
      pickupLocation: data?.pickupLocation,
      dateTimeOfService: {
        date: data?.dateTimeOfService?.date,
        time: data?.dateTimeOfService?.time,
      },
      // bookingDateTime: data?.bookingDateTime?.replace('00Z', '00.000Z'),
    });
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'pickup-emirates':
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            emirates: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'pickup-address':
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            address: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'pickup-region':
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            region: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'serviceTime':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            time: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'serviceDate':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            date: moment(new Date(date)).format('Y-MM-DD'),
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      // case 'bookingDateTime':
      //   updatedState = {
      //     ...state,
      //     bookingDateTime: new Date(date).toISOString(),
      //   }
      //   setState(updatedState)
      //   validationCheck(updatedState)
      //   break

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.localCourierBookings?.objectId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                detailedBookingId: data?.detailedBookingId,
                pickupLocation: state?.pickupLocation,
                dropLocation: state?.dropLocation,
                dateTimeOfService: {
                  date: state?.dateTimeOfService?.date,
                  time: state?.dateTimeOfService?.time,
                },
                process: `update-booking`,
                // bookingDateTime: state?.bookingDateTime,
              },
            ],
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.pickupLocation?.emirates?.trim() !== `` &&
      data?.pickupLocation?.address?.trim() !== `` &&
      data?.pickupLocation?.region?.trim() !== `` &&
      data?.dateTimeOfService?.date !== `` &&
      data?.dateTimeOfService?.time !== `` &&
      moment() <= moment(state?.dateTimeOfService?.date)
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ state })}>Get state</button> */}
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Booking ID
                  </Label>
                  <Input className="form-control" value={data?.bookingId} disabled />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    User Name
                  </Label>
                  <Input className="form-control" value={data?.user?.name} disabled />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="pickup-address" className="form-label">
                    Pickup Address
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="pickup-address"
                    name="pickup-address"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.pickupLocation?.address}
                  />
                </div>
                {isValidationShow && state?.pickupLocation?.address?.trim() === `` && <Error text="Kindly fill pickup address" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="pickup-emirates" className="form-label">
                    Pickup emirates
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="pickup-emirates"
                    name="pickup-emirates"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.pickupLocation?.emirates}
                  />
                </div>
                {isValidationShow && state?.pickupLocation?.emirates?.trim() === `` && <Error text="Kindly fill pickup emirates" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="pickup-region" className="form-label">
                    Pickup Region
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="pickup-region"
                    name="pickup-region"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.pickupLocation?.region}
                  />
                </div>
                {isValidationShow && state?.pickupLocation?.region?.trim() === `` && <Error text="Kindly fill  pickup region" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Date of Service <span className="lbl_mandatory">*</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    name="serviceDate"
                    id="serviceDate"
                    value={state?.dateTimeOfService?.date}
                    onChange={date => datePickHandler(`serviceDate`, date)}
                    options={{
                      enableTime: false,
                      dateFormat: 'Y-m-d',
                    }}
                  />
                </div>
                {isValidationShow && state?.dateTimeOfService?.date === `` && <Error text="Kindly fill date of service" />}
                {isValidationShow && state?.dateTimeOfService?.date !== `` && moment() > moment(state?.dateTimeOfService?.date) && (
                  <Error text="Invalid date" />
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Time of Service <span className="lbl_mandatory">*</span>
                  </Label>
                  <input
                    type="time"
                    className="form-control"
                    id="serviceTime"
                    name="serviceTime"
                    onChange={inputChangeHandler}
                    value={state?.dateTimeOfService?.time}
                  />
                </div>
                {isValidationShow && state?.dateTimeOfService?.time === `` && <Error text="Kindly fill time of service" />}
              </div>
            </Col>

            {/* <div className="mb-3">
          <Col md={12}>
            <div>
              <Label htmlFor="coupon-remarks" className="form-label">
                Booking Date & Time <span className="lbl_mandatory">*</span>
              </Label>
              <Flatpickr
                className="form-control"
                name="expiry-date"
                id="expiry-date"
                value={state?.bookingDateTime}
                onChange={(date) => 
                  datePickHandler(`bookingDateTime`, date)
                }
                options={{
                  minDate: 'today',
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                }}
              />
            </div>
          </Col>
        </div> */}
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateBookingEnbd;
