import cogoToast from 'cogo-toast';
import { useDispatch } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';

const DeletionDynamicFieldModal = ({ deleteDynamicField, onCloseHandler }) => {
  const handleYesClick = () => {
    deleteDynamicField();
    onCloseHandler();
    cogoToast.success('Dynamic field deleted successfully');
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Are you sure you want to delete this dynamic field?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseHandler}>
            Close
          </button>
          <button type="button" className="btn w-sm btn-primary " onClick={handleYesClick}>
            Sure
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeletionDynamicFieldModal;
