import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';
import { Error } from '../../../../../../Common';

const UpdateBookingModal = ({ locations, data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    // bookingDateTime: '',
    dateTimeOfService: {
      date: '',
      time: '',
    },
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    setState({
      dateTimeOfService: data?.dateTimeOfService,
    });
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'bookingTime':
        updatedState = {
          ...state,
          dateTimeOfService: {
            date: state?.dateTimeOfService?.date,
            time: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.dateTimeOfService?.time !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.meetAndGreetBookings?.objectId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                detailedBookingId: data?.detailedBookingId,
                dateTimeOfService: {
                  date: state?.dateTimeOfService?.date,
                  time: state?.dateTimeOfService?.time,
                },
                process: `update-booking`,
                // bookingDateTime: state?.bookingDateTime,
                // bookingDateTime: `${state?.bookingDateTime}Z`,
              },
            ],
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ state, data })}>Get state</button> */}
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Booking ID
                  </Label>
                  <Input className="form-control" value={data?.bookingId} disabled />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    User Name
                  </Label>
                  <Input className="form-control" value={data?.user?.name} disabled />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="bookingTime" className="form-label">
                    Booking Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <div className="area_expandable">
                    <Input
                      value={state?.dateTimeOfService?.time}
                      className="form-control"
                      type="time"
                      name="bookingTime"
                      onChange={inputChangeHandler}
                    />
                  </div>
                </div>
                {isValidationShow && state?.benefitGroupId === `` && <Error text="Please select time" />}
              </div>
            </Col>
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateBookingModal;
