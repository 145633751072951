import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CONFIRM_BOOKING_V2, GET_BOOKING_LIST_V2, GET_BOOKING_LIST_V2_EMPTY } from '../../../../../../store/actions';
import { ActionConfirmation, BreadCrumb, Loader } from '../../../../../Common';
import { Container } from 'reactstrap';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { Constant } from '../../../../../Helpers/constant';

const UniqueCouponV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [bookingType, setBookingType] = useState(['UNIQUE_COUPON']);
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading, bookingsError } = useSelector((state: any) => ({
    bookings: state?.BookingsV2?.data,
    bookingsLoading: state?.BookingsV2?.loading,
    bookingsError: state?.BookingsV2?.error,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    return () => {
      dispatch({
        type: GET_BOOKING_LIST_V2_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: currentPageNumber,
          sort_by: 'updated_at',
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_direction: 'DESC',
        },
      },
    });
  }, [dispatch]);

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Delayed Coupon Booking`) {
      // do nothing
    }
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(1);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_LIST_V2,
        payload: {
          data: {
            booking_type: bookingType,
            page: 0,
            page_size: Constant.TABLE_PAGE_SIZE,
            sort_direction: 'DESC',
            ...filters,
          },
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- FILTER RESET HANDLER ---------------------------- */
  const onResetHandler = () => {
    setCurrentPageNumber(1);

    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: 0,
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_direction: 'DESC',
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber + 1);

    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: pageNumber + 1,
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_by: currentlyAppliedFilters?.sort_by ?? 'updated_at',
          sort_direction: 'DESC',
        },
      },
    });
  };

  useEffect(() => {
    if (bookingsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsLoading]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}

          <>
            <BreadCrumb
              title="Delayed Coupon Booking"
              navigationby={breadcrumNavigationHandler}
              navigation={[`Dashboard`, `Unique Coupon Booking`]}
            />
            <Filter data={{}} filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} onReset={onResetHandler} />

            <Table
              data={bookings?.result}
              totalData={bookings?.total_count}
              pageNumber={currentPageNumber}
              pageHandler={paginationHandler}
              appliedFilters={currentlyAppliedFilters}
            />
          </>
        </Container>
      </div>
    </>
  );
};

export default UniqueCouponV2;
