import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';

import { CANCEL_BOOKING_GOLF_EMPTY, CANCEL_BOOKING_GOLF } from '../../../../../../../../store/actions';

const CancelBooking = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { cancelBooking } = useSelector(state => ({
    cancelBooking: state?.CancelBookingGolf?.data,
  }));

  useEffect(() => {
    if (cancelBooking !== null) {
      onCloseHandler();
      return () => {
        dispatch({
          type: CANCEL_BOOKING_GOLF_EMPTY,
        });
      };
    }
  }, [cancelBooking, dispatch, onCloseHandler]);

  const handleYesClick = () => {
    dispatch({
      type: CANCEL_BOOKING_GOLF,
      payload: {
        urlParams: {
          booking_id: data?.booking_id,
        },
      },
    });
  };

  return (
    <Modal isOpen={true} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-2 mx-sm-2">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-1 mb-0">Are you sure you want to Cancel this booking?</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseHandler}>
            Close
          </button>
          <button type="button" className="btn w-sm btn-primary" onClick={handleYesClick}>
            Sure
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CancelBooking;
