import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader, Input, Label, Col } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

const DriverAssignModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    name: '',
    mobile: '',
    dateTimeOfService: {
      date: '',
      time: '',
    },
    // pickupDate: '',
    // pickupTime: '',
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = { ...state, name: value };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'mobile':
        updatedState = { ...state, mobile: value };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'serviceTime':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            time: value,
          },
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'serviceDate':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            date: moment(new Date(date)).format('Y-MM-DD'),
          },
          // bookingDateTime: new Date(date).toISOString(),
          // pickupDate: moment(new Date(date)).format('Y-MM-DD'),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'pickUpDate':
        updatedState = {
          ...state,
          pickupDate: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'pickUpTime':
        updatedState = {
          ...state,
          pickupTime: new Date(date).toISOString(),
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.name !== `` && data?.mobile !== `` && data?.dateTimeOfService?.date !== `` && data?.dateTimeOfService?.time !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.localCourierBookings?.objectId,
          bookingsData: [
            {
              objectId: data?.bookingId,
              detailedBookingId: data?.detailedBookingId,
              process: `update-booking`,
              // status: 4,
              driver: {
                ...state,
                dateTimeOfService: {
                  date: state.dateTimeOfService?.date,
                  time: state.dateTimeOfService?.time,
                },
                // pickupDate: `${moment(state.pickupDate).format('YYYY-MM-DD')}`,
                // pickupTime: `${new Date(
                //   state.pickupTime,
                // ).getHours()}:${new Date(state.pickupTime).getMinutes()}`,
              },
            },
          ],
        },
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Driver Assign
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <form>
          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="name" className="form-label">
                  Booking ID
                </Label>
                <Input className="form-control" value={data?.bookingId} disabled />
              </div>
            </Col>
          </div>
          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="name" className="form-label">
                  User Name
                </Label>
                <Input className="form-control" value={data?.user?.name} disabled />
              </div>
            </Col>
          </div>
          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="name" className="form-label">
                  Name <span className="lbl_mandatory">*</span>
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  className="form-control"
                  value={state?.name}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="mobile" className="form-label">
                  Mobile No. <span className="lbl_mandatory">*</span>
                </Label>
                <Input
                  id="mobile"
                  name="mobile"
                  type="number"
                  onWheel={e => e.target.blur()}
                  placeholder="Mobile"
                  className="form-control"
                  value={state?.mobile}
                  onChange={inputChangeHandler}
                />
              </div>
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="pickUpDate" className="form-label" style={{ position: 'relative' }}>
                  Pick up Date <span className="lbl_mandatory">*</span>
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  name="serviceDate"
                  id="serviceDate"
                  value={state?.dateTimeOfService?.date}
                  onChange={date => datePickHandler(`serviceDate`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
              </div>
            </Col>
          </div>

          <div className="mb-3">
            <Col md={12}>
              <div>
                <Label htmlFor="pickUpTime" className="form-label" style={{ position: 'relative' }}>
                  Pick up Time <span className="lbl_mandatory">*</span>
                  <span className="icon_time">
                    <i className="ri-time-line"></i>
                  </span>
                </Label>
                <input
                  type="time"
                  className="form-control"
                  id="serviceTime"
                  name="serviceTime"
                  onChange={inputChangeHandler}
                  value={state?.dateTimeOfService?.time}
                />
              </div>
            </Col>
          </div>

          {/* <div className="mb-3">
            <Col md={12}>
              <div>
                <Label
                  htmlFor="pickUpDate"
                  className="form-label"
                  style={{ position: 'relative' }}
                >
                  Pick up Date <span className="lbl_mandatory">*</span>
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>

                <Flatpickr
                  className="form-control"
                  name="pickUpDate"
                  id="pickUpDate"
                  value={state?.pickupDate}
                  onChange={(date) => datePickHandler(`pickUpDate`, date)}
                  options={{
                    minDate: 'today',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }}
                />
              </div>
            </Col>
          </div> */}

          {/* <div className="mb-3">
            <Col md={12}>
              <div>
                <Label
                  htmlFor="pickUpTime"
                  className="form-label"
                  style={{ position: 'relative' }}
                >
                  Pick up Time <span className="lbl_mandatory">*</span>
                  <span className="icon_time">
                    <i className="ri-time-line"></i>
                  </span>
                </Label>
                <Flatpickr
                  className="form-control"
                  name="pickUpTime"
                  id="pickUpTime"
                  value={state?.pickupTime}
                  onChange={(date) => datePickHandler(`pickUpTime`, date)}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i',

                    // minTime: '16:00',
                    // maxTime: '22:30',
                    // minDate: 'today',
                    // enableTime: true,
                    // dateFormat: 'Y-m-d H:i',
                  }}
                />
              </div>
            </Col>
          </div> */}
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default DriverAssignModal;
