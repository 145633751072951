import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Offers from './Components/Offers';
import { Loader } from '../../../../../../../Common';
import VerificationOTP from '../Common/VerificationOTP';
import GeneratedVoucher from '../Common/GeneratedVoucher';
import OfferInvoiceModal from './Components/OfferInvoiceModal';
import ExistingCustomerForm from './Components/ExistingCustomerForm';
import {
  CREATE_BOOKING,
  CREATE_CLIENT_USER,
  CREATE_CLIENT_USER_EMPTY,
  GET_BENEFIT_ITEMS,
  GET_BENEFIT_ITEMS_EMPTY,
  GET_BINS_LIST_EMPTY,
  GET_CLIENT_BENEFIT_CONFIG,
  GET_CLIENT_BENEFIT_CONFIG_EMPTY,
  GET_MEMBERSHIPS_DATA,
  GET_MEMBERSHIPS_DATA_EMPTY,
  GET_USER_DETAILS_EMPTY,
  VALIDATE_USER,
  VALIDATE_USER_EMPTY,
  VENDOR_OTP_VALIDATE,
  VENDOR_OTP_VALIDATE_EMPTY,
  VENDOR_RESEND_OTP,
  VENDOR_RESEND_OTP_EMPTY,
} from '../../../../../../../../store/actions';

const ExistingCustomer = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [bookingsDetails, setBookingsDetails] = useState(null);

  const [verifyCustomerData, setVerifyCustomerData] = useState(null);
  const [selectedBenefitItem, setSelectedBenefitItem] = useState('');

  const [isShowInvoiceModal, setIsShowInvoiceModal] = useState(false);
  const [dataForBooking, setDataForBooking] = useState(null);
  const [membership, setMembership] = useState('');
  const [isUserFound, setIsUserFound] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // CLIENT USER
  const { createUser } = useSelector(state => ({
    createUser: state?.CreateClientUser?.data,
  }));

  // GET MEMBERSHIPS
  const { membershipData, membershipDataLoading } = useSelector(state => ({
    membershipData: state?.GetMembershipsData?.data?.results,
    membershipDataLoading: state?.GetMembershipsData?.loading,
  }));

  // VERIFY USER
  const { validateUser, validateUserLoading } = useSelector(state => ({
    validateUser: state?.ValidateUser?.data,
    validateUserLoading: state?.ValidateUser?.loading,
  }));

  // RESEND OTP
  const { resendOTPResLoading } = useSelector(state => ({
    resendOTPResLoading: state?.VendorResendOtp?.loading,
  }));

  // OTP VALIDATE
  const { otpValidate, otpValidateLoading } = useSelector(state => ({
    otpValidate: state?.VendorOtpValidate?.data,
    otpValidateLoading: state?.VendorOtpValidate?.loading,
  }));

  // GET BENEFIT CONFIG
  const { clientBenefitConfig, clientBenefitConfigLoading } = useSelector(state => ({
    clientBenefitConfig: state?.GetClientBenefitConfig.data?.results,
    clientBenefitConfigLoading: state?.GetClientBenefitConfig?.loading,
  }));

  // GET BENEFIT ITEMS
  const { getBenefitItems, getBenefitItemsLoading } = useSelector(state => ({
    getBenefitItems: state?.GetBenefitItems.data?.results,
    getBenefitItemsLoading: state?.GetBenefitItems?.loading,
  }));

  // CREATE BOOKING
  const { createBooking, createBookingLoading } = useSelector(state => ({
    createBooking: state.CreateBooking.data,
    createBookingLoading: state.CreateBooking.loading,
  }));

  // USER DETAILS
  const { userDetails, userDetailsLoading } = useSelector(state => ({
    userDetails: state?.GetUserDetails?.data,
    userDetailsLoading: state?.GetUserDetails?.loading,
  }));

  useEffect(() => {
    if (otpValidate !== null && userDetails?.users?.length === 0) {
      let auth = JSON.parse(window.localStorage.getItem('authUser'));
      auth = { validateOtpToken: otpValidate?.headers?.authorization, ...auth };
      localStorage.setItem('authUser', JSON.stringify(auth));

      dispatch({
        type: GET_MEMBERSHIPS_DATA,
        endpoint: '/client/memberships',
        payload: { headers: { Authorization: otpValidate?.headers?.authorization } },
      });
    }
  }, [otpValidate]);

  useEffect(() => {
    if (membershipData?.length > 0 && userDetails?.users?.length === 0) {
      let auth = JSON.parse(window.localStorage.getItem('authUser'));
      auth = { validateOtpToken: otpValidate?.headers?.authorization, ...auth };
      localStorage.setItem('authUser', JSON.stringify(auth));

      setMembership(membershipData?.[0]?.['objectId']);

      dispatch({
        type: GET_CLIENT_BENEFIT_CONFIG,
        endpoint: '/v2/client/benefit-configs',
        payload: {
          urlParam: {
            benefitGroupId: membershipData?.[0]?.['benefitGroupId'],
            membershipId: membershipData?.[0]?.['objectId'],
          },
          headers: { Authorization: otpValidate?.headers?.authorization },
        },
      });

      // dispatch({
      //   type: VENDOR_RESEND_OTP_EMPTY,
      // });
      // dispatch({
      //   type: VENDOR_OTP_VALIDATE_EMPTY,
      // });
      // dispatch({
      //   type: VALIDATE_USER_EMPTY,
      // });
      dispatch({
        type: GET_USER_DETAILS_EMPTY,
      });
    }
  }, [membershipData]);

  useEffect(() => {
    if (otpValidate !== null && isUserFound) {
      let auth = JSON.parse(window.localStorage.getItem('authUser'));
      auth = { validateOtpToken: otpValidate?.headers?.authorization, ...auth };
      localStorage.setItem('authUser', JSON.stringify(auth));

      const { benefitGroupId, objectId } = otpValidate.data.data;

      dispatch({
        type: GET_CLIENT_BENEFIT_CONFIG,
        endpoint: '/v2/client/benefit-configs',
        payload: {
          urlParam: {
            benefitGroupId: benefitGroupId,
            membershipId: objectId,
          },
          headers: { Authorization: otpValidate?.headers?.authorization },
        },
      });
    }
  }, [otpValidate]);

  useEffect(() => {
    if (clientBenefitConfig?.length > 0) {
      const newUserBenefitConfig = clientBenefitConfig?.filter(benefitConfig => benefitConfig?.allocatedTo === `existing_user`);

      if (newUserBenefitConfig?.length > 0) {
        dispatch({
          type: GET_BENEFIT_ITEMS,
          endpoint: '/v2/client/benefit-items',
          payload: {
            urlParam: {
              benefitConfigId: newUserBenefitConfig?.[0]?.objectId,
            },
            headers: { Authorization: otpValidate?.headers?.authorization },
          },
        });

        setBookingsDetails({
          membershipId: otpValidate.data.data?.objectId ?? membership,
          benefitConfigId: newUserBenefitConfig?.[0]?.objectId,
        });
      }
    }
  }, [clientBenefitConfig]);

  useEffect(() => {
    if (getBenefitItems?.length > 0) {
      setSelectedBenefitItem(getBenefitItems?.[0]?.['objectId']);
    }
  }, [getBenefitItems]);

  /* ---------------------------- ON GENERATE VOUCHER ---------------------------- */
  const onGenerateVoucher = () => {
    const selectedServiceItem = getBenefitItems?.filter(benefitItem => benefitItem?.objectId === selectedBenefitItem);

    if (selectedServiceItem?.length > 0 && selectedBenefitItem) {
      setDataForBooking({
        benefitConfigId: bookingsDetails?.benefitConfigId,
        membershipId: bookingsDetails?.membershipId,
        serviceId: selectedServiceItem?.[0]?.service?.id,
      });
      setIsShowInvoiceModal(true);
    }
  };

  /* ---------------------------- ON SUBMIT INVOICE ---------------------------- */
  const onSubmitInvoice = invoiceData => {
    setIsShowInvoiceModal(false);
    dispatch({
      type: VALIDATE_USER_EMPTY,
    });
    generateCouponBooking({ ...dataForBooking }, invoiceData);
  };

  /* ---------------------------- CREATE COUPON BOOKING ---------------------------- */
  const generateCouponBooking = (bookingData, invoiceData) => {
    setBookingsDetails({ ...bookingsDetails, serviceId: getBenefitItems?.[0]?.service?.id });
    let userId = JSON.parse(localStorage.getItem('authUser'))?.data?.userId;

    if (getBenefitItems != null) {
      dispatch({
        type: CREATE_BOOKING,
        endpoint: '/v2/booking',
        payload: {
          data: {
            process: 'initiate-booking',
            vendor: {
              vendorId: userId,
            },
            ...invoiceData,
          },
          headers: { Authorization: otpValidate?.headers?.authorization },

          urlParams: {
            ...bookingData,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (createBooking !== null) {
      dispatch({
        type: GET_BINS_LIST_EMPTY,
      });
      dispatch({
        type: CREATE_CLIENT_USER_EMPTY,
      });
      dispatch({
        type: GET_MEMBERSHIPS_DATA_EMPTY,
      });
      dispatch({
        type: GET_BENEFIT_ITEMS_EMPTY,
      });
      dispatch({
        type: GET_CLIENT_BENEFIT_CONFIG_EMPTY,
      });
      dispatch({
        type: VENDOR_RESEND_OTP_EMPTY,
      });
      dispatch({
        type: VENDOR_OTP_VALIDATE_EMPTY,
      });
      dispatch({
        type: VALIDATE_USER_EMPTY,
      });
      dispatch({
        type: GET_USER_DETAILS_EMPTY,
      });
    }
  }, [createBooking]);

  /* ---------------------------- RESEND OTP HANDLER ---------------------------- */
  const onResendOTPHandler = () => {
    dispatch({
      type: VENDOR_RESEND_OTP,
      payload: {
        headers: {
          Authorization: localStorage?.getItem('landmarkAuthToken'),
          'Project-Code': 'ALRAJHI_LANDMARK',
        },
      },
    });
  };

  /* ---------------------------- SELECT BENEFIT ITEM ---------------------------- */
  const onSelectBenefitItem = e => {
    setSelectedBenefitItem(e?.target?.value);
  };

  /* ---------------------------- OTP MODAL CLOSE HANDLER ---------------------------- */
  const onCloseHandler = () => {
    dispatch({
      type: VALIDATE_USER_EMPTY,
    });
  };

  /* ---------------------------- OTP SUBMIT HANDLER ---------------------------- */
  const onConfirmHandler = otp => {
    dispatch({
      type: VENDOR_OTP_VALIDATE,
      payload: {
        data: {
          otp,
          ...verifyCustomerData,
        },
        headers: {
          token: localStorage?.getItem('landmarkAuthToken'),
        },
      },
    });
  };

  /* ---------------------------- VERIFY USER HANDLER ---------------------------- */
  const onVerifyUser = payloadData => {
    dispatch({
      type: GET_BINS_LIST_EMPTY,
    });
    dispatch({
      type: CREATE_CLIENT_USER_EMPTY,
    });
    dispatch({
      type: GET_MEMBERSHIPS_DATA_EMPTY,
    });
    dispatch({
      type: GET_BENEFIT_ITEMS_EMPTY,
    });
    dispatch({
      type: GET_CLIENT_BENEFIT_CONFIG_EMPTY,
    });
    dispatch({
      type: VENDOR_RESEND_OTP_EMPTY,
    });
    dispatch({
      type: VENDOR_OTP_VALIDATE_EMPTY,
    });
    // dispatch({
    //   type: VALIDATE_USER_EMPTY,
    // });
    // dispatch({
    //   type: GET_USER_DETAILS_EMPTY,
    // });

    if (userDetails?.users?.length === 0) {
      //
      setVerifyCustomerData(payloadData);
      dispatch({
        type: CREATE_CLIENT_USER,
        payload: {
          data: payloadData,
        },
      });
    } else {
      setVerifyCustomerData(payloadData);

      dispatch({
        type: VALIDATE_USER,
        payload: {
          data: payloadData,
        },
      });
    }
  };

  /* ---------------------------- LOADER HANDLER ---------------------------- */
  useEffect(() => {
    if (
      clientBenefitConfigLoading ||
      getBenefitItemsLoading ||
      createBookingLoading ||
      validateUserLoading ||
      membershipDataLoading ||
      userDetailsLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [clientBenefitConfigLoading, getBenefitItemsLoading, createBookingLoading, validateUserLoading, membershipDataLoading, userDetailsLoading]);

  return (
    <div>
      {loading && <Loader />}
      {createBooking === null ? (
        <ExistingCustomerForm onSubmit={onVerifyUser} benefitItems={getBenefitItems} isUserFound={isUserFound} setIsUserFound={setIsUserFound} />
      ) : (
        <GeneratedVoucher data={createBooking} flag={`existing_user`} />
      )}
      {validateUser?.data?.status === `success` && clientBenefitConfig === undefined && (
        <VerificationOTP
          data={verifyCustomerData}
          loading={resendOTPResLoading || otpValidateLoading || clientBenefitConfigLoading}
          isResendLoader={resendOTPResLoading}
          onClose={onCloseHandler}
          onConfirm={onConfirmHandler}
          onResend={onResendOTPHandler}
        />
      )}

      {createUser?.status === `success` && userDetails?.users?.length === 0 && verifyCustomerData !== null && (
        <VerificationOTP
          data={verifyCustomerData}
          loading={resendOTPResLoading || otpValidateLoading || clientBenefitConfigLoading}
          isResendLoader={resendOTPResLoading}
          onClose={onCloseHandler}
          onConfirm={onConfirmHandler}
          onResend={onResendOTPHandler}
        />
      )}
      {createBooking === null && (
        <Offers
          data={getBenefitItems}
          onSelectBenefitItem={onSelectBenefitItem}
          selectedBenefitItem={selectedBenefitItem}
          onGenerateVoucher={onGenerateVoucher}
        />
      )}
      {dataForBooking !== null && isShowInvoiceModal && (
        <OfferInvoiceModal
          selectedBenefitItem={getBenefitItems?.filter(benefitItem => benefitItem?.objectId === selectedBenefitItem)?.[0]}
          data={verifyCustomerData}
          onSubmit={onSubmitInvoice}
          onClose={() => setIsShowInvoiceModal(false)}
        />
      )}
    </div>
  );
};

export default ExistingCustomer;
