import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';
import { formattedWithComma, hasPermission } from '../../../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';

const Table = props => {
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Bookings');

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.status));

      const fetchColums = [
        {
          name: 'S.No',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Booking ID',
          selector: row => row?.bookingId,
          wrap: true,
          width: '130px',
        },
        {
          name: 'Membership ID',
          selector: row => row?.membershipId,
          wrap: true,
          width: '180px',
        },
        {
          name: 'User Name',
          selector: row => row?.name,
          wrap: true,
          width: '170px',
        },
        {
          name: 'User Mobile',
          selector: row => row?.mobile,
          width: '160px',
          wrap: true,
        },
        {
          name: 'User Email',
          selector: row => row?.email,
          width: '220px',
          wrap: true,
        },
        {
          name: 'Category',
          selector: row => row?.categoryName,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Partner',
          selector: row => row?.partnerName,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Facility',
          selector: row => row?.facilityName,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Service',
          selector: row => row?.serviceName,
          width: '250px',
          wrap: true,
        },
        {
          name: 'Area',
          selector: row => row?.area,
          width: '160px',
          wrap: true,
        },
        {
          name: 'Region',
          selector: row => row?.region,
          width: '170px',
          wrap: true,
        },
        {
          name: 'SubRegion',
          selector: row => row?.subRegion,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Country',
          selector: row => row?.country,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Redeemed At',
          selector: row => row?.redeemedAt,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Confirmed At',
          selector: row => row?.confirmBy,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Cancelled At',
          selector: row => row?.cancelBy,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Created At',
          selector: row => row?.createdAt,
          width: '170px',
          wrap: true,
        },
        {
          name: 'Status',
          width: '160px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES_V2.filter(status => status?.bookingStatus === row?.bookingStatus)[0]?.bgColor
              }`}
            >
              {row?.bookingStatus}
            </span>
          ),
        },
      ];
      setColumns(fetchColums);

      setData(
        props?.data?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + index + 1 - Constant.TABLE_PAGE_SIZE}.`,
            bookingId: data?.booking_id || `NA`,
            membershipId: data?.membership_id || `NA`,
            name: data?.user?.firstName || data?.user?.lastName ? `${data?.user?.firstName || ''} ${data?.user?.lastName}` : `NA`,
            mobile: data?.user?.phoneNumber || `NA`,
            email: data?.user?.email || `NA`,
            partnerName: data?.booking_display?.benefitPartner?.name || 'NA',
            categoryName: data?.booking_display?.benefitCategory?.name || 'NA',
            facilityName: data?.booking_display?.benefitFacility?.name || 'NA',
            area: data?.booking_display?.benefitFacility?.area || 'NA',
            region: data?.booking_display?.benefitFacility?.region?.name || 'NA',
            subRegion: data?.booking_display?.benefitFacility?.sub_region?.name || 'NA',
            country: data?.booking_display?.benefitFacility?.country?.name || 'NA',
            serviceName: data?.booking_display?.displayName || 'NA',

            redeemedAt: data?.qr_booking_details?.IsRedeemed
              ? moment(new Date(`${data?.qr_booking_details?.RedeemedAt}`)).format('DD-MM-YYYY, h:mm A')
              : 'NA',
            confirmBy: data?.confirmBy ? moment(new Date(`${data?.confirmBy}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            cancelBy: data?.cancel_at ? moment(new Date(`${data?.cancel_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            createdAt: data?.created_at ? moment(new Date(`${data?.created_at}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            bookingStatus: data?.booking_status,
            data,
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{currentFilteredBookingTitle}</h4>

              {/* {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download dump
                </span>
              )} */}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma((props?.pageNumber - 1) * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma((props?.pageNumber - 1) * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber - 1} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
