import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';
import { Accordion, AccordionItem, Card, CardBody, Col, Collapse, Input, Row, TabPane } from 'reactstrap';
import CreateBenefitGroupItemsModal from '../Modals/CreateBenefitGroupItems';
import DisplayPropertiesSettings from '../Modals/DisplayPropertiesSettings';
import CouponSettings from '../Modals/CouponSettings';
import BookingSettings from '../Modals/BookingSettings';
import BenefitForSubCategoryModal from '../Modals/BenefitForSubCategory';
import {
  GET_SERVICES_IN_BENEFIT_ITEM,
  CREATE_INVENTORY_QUOTA_EMPTY,
  GET_INVENTORY_QUOTA,
} from '../../../../../../../../store/application/actionType';
import CreateOrConditionModal from '../Modals/CreateOrCondition';
import { Loader } from '../../../../../../../Common';
import PriceSettings from '../Modals/PriceSettings';

const BenefitGroupItems = props => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [groupItemList, setGroupItemList] = useState([]);

  const [isShowCreateBenefitGrpItemModal, setIsShowCreateBenefitGrpItemModal] = useState(false);

  const [isShowDisplayPropertyModal, setIsShowDisplayPropertyModal] = useState(false);
  const [isShowCouponSettingsModal, setIsShowCouponSettingsModal] = useState(false);
  const [isShowBookingSettingsModal, setIsShowBookingSettingsModal] = useState(false);
  const [isShowPriceSettingsModal, setIsShowPriceSettingsModal] = useState(false);

  const [isShowBenefitForSubCategoryModal, setIsShowBenefitForSubCategoryModal] = useState(false);
  const [currentSelectedBenefit, setCurrentSelectedBenefit] = useState(null);

  const [isShowORConditionModal, setIsShowORConditionModal] = useState(false);

  const iconChangeHandler = targetIndex => {
    let tempgroupItemList = [...groupItemList];
    tempgroupItemList = tempgroupItemList?.map((data, index) => {
      if (targetIndex === index) {
        return {
          ...data,
          leftIconArrayList: !data?.leftIconArrayList,
        };
      } else {
        return {
          ...data,
          leftIconArrayList: false,
        };
      }
    });
    setGroupItemList(tempgroupItemList);
  };

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Service
  const { servicesInBenefitItem } = useSelector(state => ({
    servicesInBenefitItem: state?.GetServicesInBenefitItem?.data,
  }));

  // Post Quota
  const { createInventoryQuota, createInventoryQuotaLoading } = useSelector(state => ({
    createInventoryQuota: state?.CreateInventoryQuota?.data,
    createInventoryQuotaLoading: state?.CreateInventoryQuota?.loading,
  }));

  // Get Quota
  const { inventoryQuota, inventoriesQuotaLoading } = useSelector(state => ({
    inventoryQuota: state?.GetInventoryQuota?.data,
    inventoriesQuotaLoading: state?.GetInventoryQuota?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (servicesInBenefitItem !== null) {
      setIsShowBenefitForSubCategoryModal(true);
    }
  }, [servicesInBenefitItem]);

  useEffect(() => {
    if (createInventoryQuota !== null) {
      dispatch({
        type: CREATE_INVENTORY_QUOTA_EMPTY,
      });

      dispatch({
        type: GET_INVENTORY_QUOTA,
      });
    }
  }, [createInventoryQuota]);

  useEffect(() => {
    if (inventoryQuota === null) {
      dispatch({
        type: GET_INVENTORY_QUOTA,
      });
    }
  }, []);

  /* ---------------------------- ADD NEW BENEFIT ITEM HANDLER ---------------------------- */
  const newlyCreatedBenefitGroupItemHandler = data => {
    setGroupItemList([...groupItemList, { ...data, leftIconArrayList: false }]);
  };

  /* ---------------------------- ACTION BUTTON SUBMIT HANDLER ---------------------------- */
  const actionBtnSubmitHandler = data => {
    let tempGroupItemList = [...groupItemList];

    tempGroupItemList[data?.currentIndex] = data?.data;
    setGroupItemList(tempGroupItemList);
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (label, index, data) => {
    let tempGroupItemList = JSON.parse(JSON.stringify(groupItemList));

    if (label === `delayedQuotaType`) {
      const tempGroupItem = { ...tempGroupItemList?.[index] };

      tempGroupItem['benefitGroupItem']['completeData']['quota']['delayedQuotaType'] =
        data?.benefitGroupItem?.completeData?.quota?.delayedQuotaType == 0 ? 1 : 0;
      tempGroupItemList?.splice(index, 1, tempGroupItem);
    } else if (label === `totalQuotaSubtract`) {
      let totalQuota = parseInt(data?.benefitGroupItem?.completeData?.totalCount);
      if (totalQuota > 0) {
        totalQuota--;
        tempGroupItemList[index]['benefitGroupItem']['completeData']['totalCount'] = totalQuota;
      }
    } else if (label === `totalQuotaAddition`) {
      let totalQuota = parseInt(data?.benefitGroupItem?.completeData?.totalCount);
      if (totalQuota >= 0) {
        totalQuota++;
        tempGroupItemList[index]['benefitGroupItem']['completeData']['totalCount'] = totalQuota;
      }
    } else if (label === `quotaSubtract`) {
      let delayedMinTat = parseInt(data?.benefitGroupItem?.completeData?.quota?.delayedMinTat);
      if (delayedMinTat > 0) {
        delayedMinTat--;

        tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'] = {
          ...tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'],
          delayedMinTat,
        };
      }
    } else if (label === `quotaAddition`) {
      let delayedMinTat = parseInt(data?.benefitGroupItem?.completeData?.quota?.delayedMinTat);
      if (delayedMinTat >= 0) {
        delayedMinTat++;

        tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'] = {
          ...tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'],
          delayedMinTat,
        };
      }
    } else if (label === `durationSubtract`) {
      let delayedMinValue = parseInt(data?.benefitGroupItem?.completeData?.quota?.delayedMinValue);
      if (delayedMinValue > 0) {
        delayedMinValue--;
        tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'] = {
          ...tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'],
          delayedMinValue,
        };
      }
    } else if (label === `durationAddition`) {
      let delayedMinValue = parseInt(data?.benefitGroupItem?.completeData?.quota?.delayedMinValue);
      if (delayedMinValue >= 0) {
        delayedMinValue++;
        tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'] = {
          ...tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'],
          delayedMinValue,
        };
      }
    }

    setGroupItemList([...tempGroupItemList]);
  };

  const inputSelectHandler = (label, index, data, e) => {
    let tempGroupItemList = [...groupItemList];

    if (label === `unit`) {
      tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'] = {
        ...tempGroupItemList[index]['benefitGroupItem']['completeData']['quota'],
        delayedMinTatUnit: parseInt(e.target.value),
      };
    } else if (label === `workflowId`) {
      tempGroupItemList[index]['benefitGroupItem']['completeData']['workflowId'] = e.target.value;
    } else if (label === `discountType`) {
      tempGroupItemList[index]['benefitGroupItem']['completeData']['discountquota'] = {
        ...tempGroupItemList[index]['benefitGroupItem']['completeData']['discountquota'],
        type: e.target.value,
      };
    } else if (label === `orCondition`) {
      tempGroupItemList[index]['benefitGroupItem']['completeData']['orCondition'] = e.target.value;
    }

    setGroupItemList(tempGroupItemList);
  };

  const inputTypeHandler = (label, index, data, e) => {
    let tempGroupItemList = [...groupItemList];

    if (label === `discountAmount`) {
      tempGroupItemList[index]['benefitGroupItem']['completeData']['discountquota'] = {
        ...tempGroupItemList[index]['benefitGroupItem']['completeData']['discountquota'],
        amount: parseInt(e.target.value),
      };
    } else if (label === `tax`) {
      tempGroupItemList[index]['benefitGroupItem']['completeData']['discountquota'] = {
        ...tempGroupItemList[index]['benefitGroupItem']['completeData']['discountquota'],
        tax: !tempGroupItemList[index]['benefitGroupItem']['completeData']['discountquota']['tax'],
      };
    }

    setGroupItemList(tempGroupItemList);
  };

  const addBenefitForSubCategoryHandler = data => {
    setIsShowBenefitForSubCategoryModal(true);
  };

  const onClickServiceModal = data => {
    if (data?.data?.benefitGroupItemType !== 'Benefit') {
      addBenefitForSubCategoryHandler(data);
      setCurrentSelectedBenefit(data);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (createInventoryQuotaLoading || inventoriesQuotaLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createInventoryQuotaLoading, inventoriesQuotaLoading]);

  return (
    <>
      {/* <button
        onClick={e => {
          e.preventDefault();
          console.log(groupItemList);
        }}
      >
        Get State
      </button> */}
      {loading && <Loader />}
      {/*  ---------------------------- MODAL'S ----------------------------  */}
      {isShowCreateBenefitGrpItemModal && (
        <CreateBenefitGroupItemsModal
          configList={groupItemList?.map(config => config?.benefitGroupItem?.completeData?.allocationTypeId)}
          onSubmit={newlyCreatedBenefitGroupItemHandler}
          onCloseHandler={() => setIsShowCreateBenefitGrpItemModal(false)}
        />
      )}
      {isShowDisplayPropertyModal && (
        <DisplayPropertiesSettings
          data={currentSelectedBenefit}
          onSubmit={actionBtnSubmitHandler}
          onCloseHandler={() => setIsShowDisplayPropertyModal(false)}
        />
      )}
      {isShowCouponSettingsModal && (
        <CouponSettings data={currentSelectedBenefit} onSubmit={actionBtnSubmitHandler} onCloseHandler={() => setIsShowCouponSettingsModal(false)} />
      )}
      {isShowBookingSettingsModal && (
        <BookingSettings
          data={currentSelectedBenefit}
          inventoryData={inventoryQuota}
          onSubmit={actionBtnSubmitHandler}
          onCloseHandler={() => setIsShowBookingSettingsModal(false)}
        />
      )}
      {isShowPriceSettingsModal && (
        <PriceSettings
          services={servicesInBenefitItem}
          data={currentSelectedBenefit}
          onSubmit={actionBtnSubmitHandler}
          onCloseHandler={() => setIsShowPriceSettingsModal(false)}
        />
      )}
      {isShowBenefitForSubCategoryModal && (
        <BenefitForSubCategoryModal
          services={servicesInBenefitItem}
          data={currentSelectedBenefit}
          onSubmit={actionBtnSubmitHandler}
          onCloseHandler={() => setIsShowBenefitForSubCategoryModal(false)}
        />
      )}
      {isShowORConditionModal && (
        <CreateOrConditionModal
          data={currentSelectedBenefit}
          inventoryData={inventoryQuota}
          onSubmit={actionBtnSubmitHandler}
          onCloseHandler={() => setIsShowORConditionModal(false)}
        />
      )}
      {/*  ---------------------------- SCREEN ----------------------------  */}
      <TabPane tabId={2}>
        <Row>
          <Col md={4}></Col>
          <Col md={4}>
            <div className="card-body">
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-primary text-center"
                  onClick={e => {
                    e.preventDefault();
                    setIsShowCreateBenefitGrpItemModal(true);
                  }}
                >
                  + &nbsp;Create Benefit Group Items
                </button>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="card-body">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-secondary text-center"
                  onClick={e => {
                    e.preventDefault();
                    setIsShowORConditionModal(true);
                  }}
                >
                  + Inventory
                </button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card className="shadow-none">
              <CardBody>
                <div className="live-preview">
                  <Accordion className=" lefticon-accordion custom-accordionwithicon accordion-border-box" id="accordionlefticon">
                    {groupItemList?.map((data, index) => {
                      return (
                        <>
                          <AccordionItem key={index} className="position-relative">
                            <h2 className="accordion-header" id="accordionlefticonExample1">
                              <button
                                className={classnames('accordion-button position-relative', {
                                  collapsed: !data?.leftIconArrayList,
                                })}
                                id="testtt"
                                type="button"
                                onClick={e => {
                                  e.preventDefault();
                                  iconChangeHandler(index);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  fontSize: '16px',
                                }}
                              >
                                <img
                                  src={data?.benefitGroupItem?.completeData?.images?.logo}
                                  alt=""
                                  style={{
                                    width: '40px',
                                    borderRadius: '5px',
                                    display: 'inline-block',
                                  }}
                                  className="img-fluid"
                                />
                                &nbsp;
                                <strong>
                                  {data?.benefitGroupItem?.completeData?.name}
                                  {`(${data?.benefitGroupItemType})`}
                                </strong>
                                &nbsp;
                                <span className="badge rounded-pill bg-soft-primary text-black">
                                  {data?.complementaryBenefit && ` Complementary`}
                                  {data?.discountedBenefit && ` Discounted`}
                                </span>
                                <select
                                  id="orCondition"
                                  name="orCondition"
                                  className="position-absolute"
                                  value={data?.benefitGroupItem?.completeData?.orCondition}
                                  onChange={e => {
                                    inputSelectHandler(`orCondition`, index, data, e);
                                  }}
                                  style={{
                                    right: '20px',
                                    width: '125px',
                                    padding: '5px 4px',
                                    borderRadius: '6px',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={e => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <option selected disabled value=""></option>
                                  {inventoryQuota?.results?.length > 0 &&
                                    inventoryQuota?.results?.map((inventory, index) => (
                                      <option value={inventory?.objectId} key={index}>
                                        {inventory?.name}
                                      </option>
                                    ))}
                                </select>
                              </button>
                            </h2>

                            <Collapse isOpen={data?.leftIconArrayList} className="accordion-collapse" id="accor_lefticonExamplecollapse1">
                              <div className="accordion-body">
                                <div className="row m-0">
                                  <div className="col-md-12">
                                    <h5>Benefit</h5>
                                    <div className="row m-0">
                                      <div className="d-grid col-6 p-0">
                                        <button
                                          className="btn btn-success btn-lg"
                                          onClick={e => {
                                            e.preventDefault();

                                            onClickServiceModal({
                                              currentIndex: index,
                                              data: data,
                                            });
                                          }}
                                        >
                                          {data?.benefitGroupItem?.completeData?.name}
                                          <i className="bi bi-check-circle"></i>
                                        </button>
                                      </div>
                                    </div>
                                    <hr />
                                  </div>

                                  <div className="col-md-12">
                                    <h5>Complimentary Quota</h5>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="mb-3">
                                          <div className="d-flex align-items-center">
                                            <span className="text-muted">Rolling &nbsp;</span>
                                            <div className="form-switch d-inline-block ">
                                              <Input
                                                className="form-check-input code-switcher form-switch-md cursor-pointer"
                                                type="checkbox"
                                                id="delayedQuotaType"
                                                name="delayedQuotaType"
                                                checked={data?.benefitGroupItem?.completeData?.quota?.delayedQuotaType === 0 ? false : true}
                                                onChange={() => inputChangeHandler(`delayedQuotaType`, index, data)}
                                              />
                                            </div>
                                            <span> &nbsp;Static</span>
                                          </div>
                                        </div>

                                        <h6>Total Quota</h6>
                                        <div className="form-group">
                                          <div className="d-flex flex-wrap align-items-start gap-2">
                                            <div className="input-step step-primary">
                                              <button
                                                type="button"
                                                className="minus"
                                                onClick={() => {
                                                  inputChangeHandler(`totalQuotaSubtract`, index, data);
                                                }}
                                              >
                                                –
                                              </button>

                                              <input
                                                type="number"
                                                onWheel={e => e.target.blur()}
                                                className="product-quantity"
                                                value={data?.benefitGroupItem?.completeData?.totalCount}
                                                min="0"
                                                max="100"
                                              />

                                              <button
                                                type="button"
                                                className="plus"
                                                onClick={() => {
                                                  inputChangeHandler(`totalQuotaAddition`, index, data);
                                                }}
                                              >
                                                +
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <h6>Duration Quota</h6>
                                        <div className="d-flex flex-wrap align-items-start gap-2">
                                          <div className="form-group">
                                            <label htmlFor="" className="form-label">
                                              Quota
                                            </label>
                                            <div className="d-flex flex-wrap align-items-start gap-2">
                                              <div className="input-step step-primary">
                                                <button
                                                  type="button"
                                                  className="minus"
                                                  onClick={() => {
                                                    inputChangeHandler(`quotaSubtract`, index, data);
                                                  }}
                                                >
                                                  –
                                                </button>
                                                <input
                                                  type="number"
                                                  onWheel={e => e.target.blur()}
                                                  className="product-quantity"
                                                  value={data?.benefitGroupItem?.completeData?.quota?.delayedMinTat}
                                                  min="0"
                                                  max="100"
                                                />
                                                <button
                                                  type="button"
                                                  className="plus"
                                                  onClick={() => {
                                                    inputChangeHandler(`quotaAddition`, index, data);
                                                  }}
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group align-self-end">
                                            <div className="mb-2">
                                              <strong>Per</strong>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <label htmlFor="" className="form-label">
                                              Duration
                                            </label>
                                            <div className="d-flex flex-wrap align-items-start gap-2">
                                              <div className="input-step step-primary">
                                                <button
                                                  type="button"
                                                  className="minus"
                                                  onClick={() => {
                                                    inputChangeHandler(`durationSubtract`, index, data);
                                                  }}
                                                >
                                                  –
                                                </button>
                                                <input
                                                  type="number"
                                                  onWheel={e => e.target.blur()}
                                                  className="product-quantity"
                                                  value={data?.benefitGroupItem?.completeData?.quota?.delayedMinValue}
                                                  min="0"
                                                  max="100"
                                                />
                                                <button
                                                  type="button"
                                                  className="plus"
                                                  onClick={() => {
                                                    inputChangeHandler(`durationAddition`, index, data);
                                                  }}
                                                >
                                                  +
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <label htmlFor="">Unit</label>
                                            <select
                                              className="form-control"
                                              name=""
                                              id=""
                                              value={data?.benefitGroupItem?.completeData?.quota?.delayedMinTatUnit}
                                              onChange={e => inputSelectHandler(`unit`, index, data, e)}
                                            >
                                              <option value={3}>Day</option>
                                              <option value={2}>Month</option>
                                              <option value={1}>Year</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                  </div>

                                  {data?.discountedBenefit && (
                                    <div className="row mb-3">
                                      <div className="col-md-3">
                                        <h6>Discount Type</h6>
                                        <select
                                          className="form-control"
                                          name=""
                                          id=""
                                          value={data?.benefitGroupItem?.completeData?.discountquota?.type}
                                          onChange={e => {
                                            inputSelectHandler(`discountType`, index, data, e);
                                          }}
                                        >
                                          <option value="percentage">Percentage</option>
                                          <option value="amount">Amount</option>
                                        </select>
                                      </div>
                                      <div className="col-md-3">
                                        <h6>Discount Value</h6>
                                        <input
                                          type="number"
                                          onWheel={e => e.target.blur()}
                                          className="form-control"
                                          placeholder="Enter discount value"
                                          name=""
                                          id=""
                                          value={data?.benefitGroupItem?.completeData?.discountquota?.amount}
                                          onChange={e => {
                                            inputTypeHandler(`discountAmount`, index, data, e);
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-5">
                                        <h6>TAX</h6>
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="formCheck2"
                                            checked={data?.benefitGroupItem?.completeData?.discountquota?.tax}
                                            onChange={e => {
                                              inputTypeHandler(`tax`, index, data, e);
                                            }}
                                          />
                                          <label className="form-check-label">Taxes Applicable</label>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <Col md={12}>
                                    <h5>Benefit Workflow</h5>
                                    <Row>
                                      <Col md={3}>
                                        <select
                                          name="workflowId"
                                          className="form-control"
                                          id="workflowId"
                                          value={data?.benefitGroupItem?.completeData?.workflowId}
                                          onChange={e => {
                                            inputSelectHandler(`workflowId`, index, data, e);
                                          }}
                                        >
                                          <option value="DEFAULT">Default</option>
                                          <option value="VIA_VENDOR">Via vendor</option>
                                        </select>
                                      </Col>
                                      <Col md={3}>
                                        <a href="#!" className="btn btn-outline-primary">
                                          View
                                        </a>
                                      </Col>
                                    </Row>
                                    <hr />
                                  </Col>

                                  <Col md={12} className="align-content-around">
                                    <Row className="m-0">
                                      <Col md={3}>
                                        <h5>
                                          Display Properties &nbsp;
                                          <button
                                            className="btn btn-soft-dark btn-sm"
                                            type="button"
                                            data-bs-target="#edit-display"
                                            data-bs-toggle="modal"
                                            onClick={() => {
                                              setIsShowDisplayPropertyModal(true);
                                              setCurrentSelectedBenefit({
                                                currentIndex: index,
                                                data: data,
                                              });
                                            }}
                                          >
                                            <i className="ri-settings-4-line"></i>
                                          </button>
                                        </h5>
                                      </Col>

                                      <Col md={3}>
                                        <h5>
                                          Coupon Settings &nbsp;
                                          <button
                                            className="btn btn-soft-dark btn-sm"
                                            type="button"
                                            data-bs-target="#edit-display"
                                            data-bs-toggle="modal"
                                            onClick={() => {
                                              setIsShowCouponSettingsModal(true);
                                              setCurrentSelectedBenefit({
                                                currentIndex: index,
                                                data: data,
                                              });
                                            }}
                                          >
                                            <i className="ri-settings-4-line"></i>
                                          </button>
                                        </h5>
                                      </Col>

                                      <Col md={3}>
                                        <h5>
                                          Booking Settings &nbsp;
                                          <button
                                            className="btn btn-soft-dark btn-sm"
                                            type="button"
                                            data-bs-target="#edit-display"
                                            data-bs-toggle="modal"
                                            onClick={() => {
                                              setIsShowBookingSettingsModal(true);
                                              setCurrentSelectedBenefit({
                                                currentIndex: index,
                                                data: data,
                                              });
                                            }}
                                          >
                                            <i className="ri-settings-4-line"></i>
                                          </button>
                                        </h5>
                                      </Col>

                                      <Col md={3}>
                                        <h5>
                                          Price Settings &nbsp;
                                          <button
                                            className="btn btn-soft-dark btn-sm"
                                            type="button"
                                            data-bs-target="#edit-display"
                                            data-bs-toggle="modal"
                                            onClick={() => {
                                              setIsShowPriceSettingsModal(true);
                                              setCurrentSelectedBenefit({
                                                currentIndex: index,
                                                data: data,
                                              });
                                            }}
                                          >
                                            <i className="ri-settings-4-line"></i>
                                          </button>
                                        </h5>
                                      </Col>
                                    </Row>
                                  </Col>
                                </div>
                              </div>
                            </Collapse>
                          </AccordionItem>
                        </>
                      );
                    })}
                  </Accordion>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <div className="d-flex align-items-start gap-3 mt-4">
          {/* <button
            type="button"
            className="btn btn-light btn-label previestab"
            // onClick={() => {
            //   props.toggleTab(props.activeTab - 1)
            // }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
            Back to Benefit Group(s)
          </button> */}
          <button
            type="button"
            className="btn btn-primary btn-label right ms-auto nexttab"
            onClick={() => {
              props.onPressNext(groupItemList);
              // props.toggleTab(props.activeTab + 1)
            }}
          >
            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
            Continue to Next Step
          </button>
        </div>
      </TabPane>
    </>
  );
};

export default BenefitGroupItems;
