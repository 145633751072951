import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectStats from './Components/ProjectStats';
import { GET_SURGE_REPORT, GET_USER_LIST, GET_WALLETS } from '../../../../../store/application/actionType';
import { Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import MisReports from './Components/MisReports';
import SurgeReport from './Components/SurgeReport';

const BSESDashboard = () => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [monthName, setMonthName] = useState('');

  /* ---------------------------- REDUX STATES ---------------------------- */
  // SURGE REPORT
  const { reportData, reportLoading } = useSelector(state => ({
    reportData: state?.GetSurgeReport?.data,
    reportLoading: state?.GetSurgeReport?.loading,
  }));

  // GET WALLETS
  const { wallets, walletsLoading } = useSelector(state => ({
    wallets: state?.GetWallets?.data,
    walletsLoading: state?.GetWallets?.loading,
  }));

  // USER INFO
  const { userInfoLoading } = useSelector(state => ({
    userInfoLoading: state?.GetUsersList?.loading,
  }));

  // MIS REPORT
  const { misReportDataLoading } = useSelector(state => ({
    misReportDataLoading: state?.GetMisReport?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_WALLETS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (wallets !== null && wallets?.results?.length > 0) {
      dispatch({
        type: GET_SURGE_REPORT,
        payload: {
          data: {
            dateRange: {
              from: `${moment().startOf('month').format('YYYY-MM-DD')}`,
              to: moment(new Date()).format('YYYY-MM-DD'),
            },
            walletId: `${wallets?.results[0]?.['objectId']}`,
          },
        },
      });

      dispatch({
        type: GET_USER_LIST,
        payload: {
          data: {
            dateRange: {
              from: `${moment().subtract(1, 'months').format('YYYY-MM-DD')}`,
              to: `${moment().subtract(0, 'months').format('YYYY-MM-DD')}`,
            },
            walletId: `${wallets?.results[0]?.['objectId']}`,
          },
          urlParam: {
            pageSize: Constant?.TABLE_PAGE_SIZE,
            pageIndex: 0,
          },
        },
      });
    }
  }, [wallets]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (reportLoading || walletsLoading || userInfoLoading || misReportDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [reportLoading, walletsLoading, userInfoLoading, misReportDataLoading]);

  const currentMonthNumber = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const getMonthName = monthNo => {
    const date = new Date();
    date.setMonth(monthNo); // JavaScript months are 0-based
    return date.toLocaleString('default', { month: 'long' });
  };

  // Function to get the month name from the month number
  const getMonthNameWithYear = (monthNo, year) => {
    const monthName = getMonthName(monthNo);
    return `${monthName}_${year}`; // Format as "MonthName_Year"
  };

  // Set the initial state to the current month's name
  useEffect(() => {
    setMonthName(getMonthNameWithYear(currentMonthNumber, currentYear)); // Set initial month name
  }, []); // Runs once when the component mounts

  // Function to update the month based on a month number
  const updateMonth = (monthNo, year) => {
    setMonthName(getMonthNameWithYear(monthNo, year));
  };

  const filterDateHandler = selectedDates => {
    if (selectedDates.length > 0 && wallets !== null && wallets?.results?.length > 0) {
      const selectedDate = selectedDates[0];
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      updateMonth(month, year);


      // Get today's date
      const today = new Date();

      // Calculate the first day of the selected month
      const firstDayOfMonth = moment([year, month]).startOf('month').toDate();

      // Calculate the last day of the selected month
      let lastDayOfMonth;
      if (year === today.getFullYear() && month === today.getMonth()) {
        lastDayOfMonth = today; // If the selected month is the current month, use today's date
      } else {
        lastDayOfMonth = moment([year, month]).endOf('month').toDate();
      }

      // Format dates as "YYYY-MM-DD" using Moment.js
      const from = moment(firstDayOfMonth).format('YYYY-MM-DD');
      const to = moment(lastDayOfMonth).format('YYYY-MM-DD');

      dispatch({
        type: GET_SURGE_REPORT,
        payload: {
          data: {
            dateRange: {
              from: from,
              to: to,
            },
            walletId: `${wallets?.results[0]?.['objectId']}`,
          },
        },
      });
    }
  };

  return (
    <>
      {loading && <Loader />}

      <Row>
        <Col md={12}>
          <ProjectStats data={reportData} filterDateHandler={filterDateHandler} monthName={monthName} />
          <SurgeReport data={reportData} />
          <MisReports data={reportData} />
        </Col>
      </Row>
    </>
  );
};

export default BSESDashboard;
