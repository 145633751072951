import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { ActionConfirmation, BreadCrumb, Loader } from '../../../../../Common';
import { Constant } from '../../../../../Helpers/constant';
import BookingDetails from './Components/Details/Details';
import {
  GET_BOOKING_LIST_V2,
  GET_BOOKING_LIST_V2_EMPTY,
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
  RESEND_MAIL,
  UPDATE_BOOKING,
  VIEW_FILE,
  PROCCESS_BOOKING_V2,
  PROCCESS_BOOKING_V2_EMPTY,
  CANCEL_BOOKING_V2_EMPTY,
  CONFIRM_BOOKING_V2_EMPTY,
} from '../../../../../../store/application/actionType';
import ConfirmModal from './Components/Modals/ConfirmModal';
import ChaufferAssignModal from './Components/Modals/ChaufferAssignAirportModal';
import CancelBooking from './Components/Modals/CancelBooking';

const PickAndDropBookingManagementV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const bookingType = ['AIRPORT_TRANSFER_BOOKING'];

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);
  const [isConfirmModalForAirportTransfer, setIsConfirmModalForAirportTransfer] = useState(false);
  const [isCancelModalShow, setIsCancelModalShow] = useState(false);
  const [isChaufferAssignShow, setIsChaufferAssignShow] = useState(false);
  const [isUpdateBookingModal, setIsUpdateBookingModal] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { bookings, bookingsLoading } = useSelector(state => ({
    bookings: state?.BookingsV2?.data,
    bookingsLoading: state?.BookingsV2?.loading,
  }));

  // Proccess Booking
  const { proccessBooking, proccessBookingLoading } = useSelector(state => ({
    proccessBooking: state?.ProccessBookingV2?.data,
    proccessBookingLoading: state?.ProccessBookingV2?.loading,
  }));

  // Cancel Booking
  const { cancelBooking, cancelBookingLoading } = useSelector(state => ({
    cancelBooking: state?.CancelBookingV2?.data,
    cancelBookingLoading: state?.CancelBookingV2?.loading,
  }));

  // Confirm Booking
  const { confirmBooking, confirmBookingLoading } = useSelector(state => ({
    confirmBooking: state?.ConfirmBookingV2?.data,
    confirmBookingLoading: state?.ConfirmBookingV2?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  // Resend Mail
  const { resendMail, resendMailLoading } = useSelector(state => ({
    resendMail: state?.ResendMail?.data,
    resendMailLoading: state?.ResendMail?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: currentPageNumber,
          sort_by: 'updated_at',
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_direction: 'DESC',
        },
      },
    });

    return () => {
      dispatch({
        type: GET_BOOKING_LIST_V2_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (proccessBooking !== null || cancelBooking !== null || confirmBooking !== null) {
      setCurrentPageNumber(1);
      dispatch({
        type: PROCCESS_BOOKING_V2_EMPTY,
      });

      dispatch({
        type: CANCEL_BOOKING_V2_EMPTY,
      });

      dispatch({
        type: CONFIRM_BOOKING_V2_EMPTY,
      });

      dispatch({
        type: GET_BOOKING_LIST_V2,
        payload: {
          data: {
            booking_type: bookingType,
            page: 1,
            sort_by: 'updated_at',
            page_size: Constant.TABLE_PAGE_SIZE,
            sort_direction: 'DESC',
          },
        },
      });
    }
  }, [proccessBooking, cancelBooking, confirmBooking]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: CLICK_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(1);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_LIST_V2,
        payload: {
          data: {
            booking_type: bookingType,
            page: 0,
            page_size: Constant.TABLE_PAGE_SIZE,
            sort_direction: 'DESC',
            ...filters,
          },
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- FILTER RESET HANDLER ---------------------------- */
  const onResetHandler = () => {
    setCurrentPageNumber(1);

    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: 0,
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_direction: 'DESC',
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber + 1);

    dispatch({
      type: GET_BOOKING_LIST_V2,
      payload: {
        data: {
          booking_type: bookingType,
          page: pageNumber + 1,
          page_size: Constant.TABLE_PAGE_SIZE,
          sort_by: currentlyAppliedFilters?.sort_by ?? 'updated_at',
          sort_direction: 'DESC',
        },
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);
    setCurrentConfirmationModalActionFor(params?.actionType);
    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(!isDetailsPage);
    } else if (params?.actionType === `PROCESS`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `CONFIRM`) {
      setIsConfirmModalForAirportTransfer(true);
    } else if (params?.actionType === `CANCEL`) {
      setIsCancelModalShow(true);
    } else if (params?.actionType === `CHAUFFER ASSIGN`) {
      setIsChaufferAssignShow(true);
    } else if (params?.actionType === `UPDATE`) {
      setIsUpdateBookingModal(true);
    } else if (params?.actionType === `RESEND_EMAIL`) {
      setIsShowConfirmationModal(true);
    }
  };

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const closeModalHandler = (flag, data) => {
    if (flag === `ConfirmationModal`) {
      setIsShowConfirmationModal(false);
      if (data === `YES`) {
        if (currentConfirmationModalActionFor === `PROCESS`) {
          dispatch({
            type: PROCCESS_BOOKING_V2,
            payload: {
              data: {
                booking_id: currentSelectedBookingDetails?.booking_id,
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CONFIRM`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                bookingsData: [
                  {
                    // ...currentSelectedBookingDetails,
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CONFIRM,
                    process: `confirm-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CANCEL`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                bookingsData: [
                  {
                    // ...currentSelectedBookingDetails,
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
                    process: `cancel-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `RESEND_EMAIL`) {
          dispatch({
            type: RESEND_MAIL,
            payload: {
              data: {
                bookingIds: [currentSelectedBookingDetails?.bookingId],
              },
            },
          });
        }
      }
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (bookingsLoading || proccessBookingLoading || downloadDumpLoading || resendMailLoading || cancelBookingLoading || confirmBookingLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsLoading, proccessBookingLoading, downloadDumpLoading, resendMailLoading, cancelBookingLoading, confirmBookingLoading]);

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Airport Transfer Booking Details`) {
      // do nothing
    }
  };

  const viewTicketHandler = details => {
    dispatch({
      type: VIEW_FILE,
      payload: {
        urlParams: {
          key: `${details?.ticketUrl}`,
          purpose: `flightTicket`,
          user_id: `${details?.user?.objectId}`,
        },
      },
    });
  };

  return (
    <>
      {loading && <Loader zIndex={true} />}

      {isShowConfirmationModal && (
        <ActionConfirmation onClose={closeModalHandler} data={currentSelectedBookingDetails} action={currentConfirmationModalActionFor} />
      )}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  MODAL ---------------------------- */}

          {isConfirmModalForAirportTransfer && (
            <ConfirmModal onCloseHandler={() => setIsConfirmModalForAirportTransfer(false)} data={currentSelectedBookingDetails} />
          )}

          {isCancelModalShow && <CancelBooking onCloseHandler={() => setIsCancelModalShow(false)} data={currentSelectedBookingDetails} />}

          {isChaufferAssignShow && <ChaufferAssignModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsChaufferAssignShow(false)} />}

          {!isDetailsPage && (
            <>
              <BreadCrumb
                title="Airport Transfer Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Airport Transfer Booking`]}
              />
              <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} onReset={onResetHandler} />
              <Table
                data={bookings?.result}
                totalData={bookings?.total_count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                appliedFilters={currentlyAppliedFilters}
              />
            </>
          )}

          {isDetailsPage && (
            <>
              <BreadCrumb
                title="Airport Transfer Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Airport Transfer Booking`, `Airport Transfer Details`]}
              />
              <BookingDetails details={currentSelectedBookingDetails} onViewTicket={viewTicketHandler} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default PickAndDropBookingManagementV2;
