import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter, Input } from 'reactstrap';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../../Helpers/constant';
import Flatpickr from 'react-flatpickr';
import { isValidAlphaNumbericWithoutSpace, isValidAplha, isValidNumber } from '../../../../../../../Helpers/Helpers';

const UpdateModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [bookings, setBookings] = useState([]);

  const [modal_varying1, setmodal_varying1] = useState(true);

  const [isOthers, setIsOthers] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(
        props?.data?.map(booking => {
          return {
            ...booking,
          };
        }),
      );
    }
  }, [props]);

  useEffect(() => {
    validationCheck(bookings);
  }, [bookings]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        ...booking,
        objectId: booking?.bookingId,
        driver: booking?.driver,
        dateTimeOfService: {
          ...booking?.dateTimeOfService,
          time: booking?.dateTimeOfService?.time === '' ? '00:00' : booking?.dateTimeOfService?.time,
        },
        detailedBookingId: booking?.detailedBookingId,
        bookingDateTime: booking?.bookingDateTime?.replace('00Z', '00.000Z'),
        remarks: booking?.remarks,
        referenceNumber: booking?.referenceNumber,
        serviceEngineer: booking?.serviceEngineer,
        process: `update-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.carRegistrationBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const cancelHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;

    let tempBooking = [...bookings];
    let selectedChangingBooking = tempBooking[index];

    switch (name) {
      case 'driverName':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          driver: {
            ...selectedChangingBooking?.driver,
            name: isValidAplha(value) ? value : selectedChangingBooking?.driver?.name,
          },
        };
        break;

      case 'driverMobile':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          driver: {
            ...selectedChangingBooking?.driver,
            mobile: isValidNumber(value) ? value : selectedChangingBooking?.driver?.mobile,
          },
        };
        break;

      case 'referenceNumber':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          referenceNumber: isValidNumber(value) ? value : selectedChangingBooking?.referenceNumber,
        };
        break;

      case 'pickupPoint':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          pickupLocation: {
            ...selectedChangingBooking?.pickupLocation,
            address: value,
          },
        };
        break;

      case 'pickupCarNumber':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          car: {
            ...selectedChangingBooking?.car,
            number: isValidAlphaNumbericWithoutSpace(value) ? value : selectedChangingBooking?.car?.number,
          },
        };
        break;

      case 'confirmedModelNumber':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          car: {
            ...selectedChangingBooking?.car,
            model: isValidAlphaNumbericWithoutSpace(value) ? value : selectedChangingBooking?.car?.model,
          },
        };
        break;

      case 'confirmation-number':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          confirmationCode: value,
        };
        break;

      case 'remarks':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          remarks: value,
        };
        break;

      default:
      // do nothing
    }
    tempBooking?.splice(index, 1, selectedChangingBooking);
    validationCheck(tempBooking);
    setBookings(tempBooking);
  };

  /* ---------------------------- DATE PICKER HANDLER ---------------------------- */
  const datePickHandler = (flag, date, index) => {
    let tempBooking = [...bookings];
    let selectedChangingBooking = tempBooking[index];
    const selectedTime = date[0]; // Extract the first (and only) date object from the array
    const value = selectedTime
      ? `${selectedTime.getHours().toString().padStart(2, '0')}:${selectedTime.getMinutes().toString().padStart(2, '0')}`
      : ''; // Extract hours and minutes as a string with leading zeros
    switch (flag) {
      case 'pickupDate':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          driver: {
            ...selectedChangingBooking?.driver,
            pickupDate: new Date(date).toISOString(),
          },
        };
        break;
      case 'pickupTime':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          driver: {
            ...selectedChangingBooking?.driver,
            pickupTime: value,
          },
        };
        break;
      default:
      // do nothing
    }

    tempBooking?.splice(index, 1, selectedChangingBooking);
    validationCheck(tempBooking);
    setBookings(tempBooking);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;

    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.driver?.name === '') {
        flag = true;
        break;
      } else if (data[i]?.driver?.mobile === '') {
        flag = true;
        break;
      } else if (data[i]?.driver?.pickupDate === '') {
        flag = true;
        break;
      } else if (data[i]?.driver?.pickupTime === '') {
        flag = true;
        break;
      } else if (data[i]?.pickupLocation?.address === '') {
        flag = true;
        break;
      } else if (data[i]?.car?.number === '') {
        flag = true;
        break;
      } else if (data[i]?.car?.model === '') {
        flag = true;
        break;
      } else if (data[i]?.referenceNumber === '') {
        flag = true;
        break;
      } else if (data[i]?.remarks === '') {
        flag = true;
        break;
      }
    }
    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Picker Name</th>
                    <th> Picker Mobile</th>
                    <th> Confirmed Pick Up date</th>
                    <th> Confirmed Pick Up time</th>
                    <th> Confirmed Exact Pickup Point</th>
                    <th> Confirmed Car plate number</th>
                    <th> Confirmed Model Number</th>
                    <th> Reference No</th>
                    <th> Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings &&
                    bookings?.map((booking, index) => (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>{booking?.bookingId}</td>
                        <td>{booking?.user?.name}</td>
                        <td>
                          <Input
                            id="driverName"
                            name="driverName"
                            type="text"
                            placeholder=""
                            style={{ width: '200px' }}
                            className="form-control"
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.driver?.name}
                          />
                        </td>
                        <td>
                          <Input
                            id="driverMobile"
                            name="driverMobile"
                            type="number"
                            onWheel={e => e.target.blur()}
                            placeholder=""
                            style={{ width: '200px' }}
                            className="form-control"
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.driver?.mobile}
                          />
                        </td>
                        <td>
                          <Flatpickr
                            className="form-control"
                            name="booking-date-time"
                            id="booking-date-time"
                            style={{ width: '200px' }}
                            value={booking.driver?.pickupDate}
                            onChange={date => datePickHandler(`pickupDate`, date, index)}
                            options={{
                              minDate: 'today',
                              enableTime: false,
                              dateFormat: 'Y-m-d ',
                            }}
                          />
                        </td>
                        <td>
                          <Flatpickr
                            className={`form-control`}
                            name={`pickupTime`}
                            id="pickupTime"
                            value={booking?.driver?.pickupTime}
                            style={{ width: '200px' }}
                            onChange={date => datePickHandler(`pickupTime`, date, index)}
                            options={{
                              // minDate: 'today',
                              noCalendar: true,
                              enableTime: true,
                              dateFormat: 'H:i',
                              time_24hr: false,
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            id="pickupPoint"
                            name="pickupPoint"
                            type="text"
                            placeholder=""
                            style={{ width: '200px' }}
                            className="form-control"
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.pickupLocation?.address}
                          />
                        </td>
                        <td>
                          <Input
                            id="pickupCarNumber"
                            name="pickupCarNumber"
                            type="text"
                            style={{ width: '200px' }}
                            placeholder=""
                            className="form-control"
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.car?.number}
                          />
                        </td>
                        <td>
                          <Input
                            id="confirmedModelNumber"
                            name="confirmedModelNumber"
                            style={{ width: '200px' }}
                            type="text"
                            placeholder=""
                            className="form-control"
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.car?.model}
                          />
                        </td>
                        <td>
                          <Input
                            id="referenceNumber"
                            name="referenceNumber"
                            type="number"
                            onWheel={e => e.target.blur()}
                            style={{ width: '200px' }}
                            placeholder=""
                            className="form-control"
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.referenceNumber}
                          />
                        </td>
                        <td>
                          <Input
                            id="remarks"
                            name="remarks"
                            style={{ width: '200px' }}
                            type="textarea"
                            placeholder=""
                            className="form-control"
                            onChange={e => inputChangeHandler(e, index)}
                            value={booking?.remarks}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <p className="text-muted m-0">Once you will click on proceed, you will not be able to change previous state.</p>
          </Row>
        </form>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <Button className="btn btn-light overlay-disable" color="primary" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
            Sure
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateModal;
