import { useEffect, useState } from 'react';

import moment from 'moment';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { useDispatch } from 'react-redux';
import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, UncontrolledDropdown } from 'reactstrap';

import { PreviewCardHeader } from '../../../../../../../Common';
import { Constant } from '../../../../../../../Helpers/constant';
import { GET_BOOKING_LIST_V2_EMPTY } from '../../../../../../../../store/actions';

const Filter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    created_at_start: '',
    created_at_end: '',
    updated_at_start: '',
    updated_at_end: '',
    booking_id: '',
    sort_by: 'updated_at',
  };
  const [filter, setFilter] = useState(initialState);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const [categories, setCategories] = useState(null);
  const [partners, setPartners] = useState(null);
  const [facilities, setFacilities] = useState(null);
  const [services, setServices] = useState(null);

  const [searchCode, setSearchCode] = useState('0');
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (props?.appliedFilters) {
      setFilter(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  useEffect(() => {
    setCategories(props?.data?.categories);
    setPartners(props?.data?.partners);
    setFacilities(props?.data?.locations);
    setServices(props?.data?.services);
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'search':
        setSearchCode(value);
        setSearchValue('');
        validationCheck({ ...filter });
        break;

      case 'search-value':
        if (searchCode !== '0') {
          setSearchValue(value);
          validationCheck({ ...filter });
        }
        break;

      case 'booking-id':
        updatedState = {
          ...filter,
          booking_id: value,
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

        updatedState = { ...filter, slaBreached: value === 'true' };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'sort_by':
        updatedState = { ...filter, sort_by: value };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'createdAtRange':
        updatedState = {
          ...filter,
          created_at_start: moment(new Date(date[0])).format('Y-MM-DD'),
          created_at_end: moment(new Date(date[1])).format('Y-MM-DD'),
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      case 'updatedAtRange':
        updatedState = {
          ...filter,
          updated_at_start: moment(new Date(date[0])).format('Y-MM-DD'),
          updated_at_end: moment(new Date(date[1])).format('Y-MM-DD'),
        };
        setFilter(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (
      (data?.searchCode !== '0' && data?.searchValue !== ``) ||
      data?.createdAtRange?.from !== `` ||
      data?.createdAtRange?.to !== `` ||
      data?.updatedAtRAnge?.from !== `` ||
      data?.updatedAtRAnge?.to !== `` ||
      status !== null
    ) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    // Prepare the filter values with new keys
    let filterValue = {
      ...filter,
      booking_id: '',
      user_id: '',
      membership_id: '',
    };

    if (searchCode !== '0' && searchValue !== ``) {
      if (searchCode === '4') {
        filterValue = {
          ...filterValue,
          booking_id: searchValue,
        };
        setFilter(filterValue);
      } else if (searchCode === '1') {
        filterValue = {
          ...filterValue,
          user_id: searchValue,
        };
      } else if (searchCode === '2') {
        filterValue = {
          ...filterValue,
          membership_id: searchValue,
        };
      }
      setFilter(filterValue);
    }

    // Call the filterHandler with the modified filterValue
    props.filterHandler({ ...filterValue, filter: true });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter(initialState);
    setIsResetDisabled(true);
    setSearchCode('0');
    setStatus(null);
    setSearchValue('');
    props.filterHandler({ ...initialState, filter: false });
    dispatch({
      type: GET_BOOKING_LIST_V2_EMPTY,
    });

    props?.onReset();
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            {/* <button onClick={() => console.log({ filter })}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="search" className="form-label">
                          Search
                        </Label>
                        <UncontrolledDropdown className="input-group">
                          <select
                            id="search"
                            name="search"
                            className="form-select truncate cursor-pointer"
                            style={{ maxWidth: '35%' }}
                            value={searchCode}
                            onChange={inputChangeHandler}
                          >
                            <option value="0">Select</option>
                            <option value="4">Booking Id</option>
                            <option value="1">User Id</option>
                            <option value="2">Membership Id</option>
                            {/* <option value="5">Membership Id</option> */}
                          </select>
                          <Input
                            id="search-value"
                            name="search-value"
                            type="text"
                            className="form-control"
                            aria-label="Text input with dropdown button"
                            value={searchValue}
                            onChange={inputChangeHandler}
                          />
                        </UncontrolledDropdown>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="created-at-range" className="form-label">
                          Created At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            onChange={date => datePickHandler(`createdAtRange`, date)}
                            value={[filter?.created_at_start, filter?.created_at_end]}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="date-of-play-range" className="form-label">
                          Updated At - Range
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className="form-control"
                            value={[filter?.updated_at_start, filter?.updated_at_end]}
                            onChange={date => datePickHandler(`updatedAtRange`, date)}
                            options={{
                              mode: 'range',
                              dateFormat: 'Y-m-d',
                              maxDate: 'today',
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="sort_by" className="form-label">
                          Sort By
                        </Label>
                        <select id="sort_by" name="sort_by" className="form-select" value={filter?.sort_by} onChange={inputChangeHandler}>
                          <option value="updated_at">Updated At</option>
                          <option value="created_at">Created At</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-3 overlay-disable" onClick={filterSubmitHandler} disabled={isFilterDisabled}>
                      Search
                    </button>

                    <button type="submit" className="btn btn-primary overlay-disable" onClick={resetFilterHandler} disabled={isResetDisabled}>
                      Reset Filter
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Filter;
