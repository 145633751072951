import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Grid, _ } from 'gridjs-react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../Helpers/constant';
import { PreviewCardHeader, TablePagination } from '../../../../../Common';
import DataTable from 'react-data-table-component';
import { formattedWithComma } from '../../../../../Helpers/Helpers';

const BookingTableLaptopScreen = ({ actionHandler, data, totalData, pageNumber, pageHandler, bankName }) => {
  const { t } = useTranslation();
  const [bookingData, setBookingData] = useState([]);
  const [cancellationAllowance, setCancellationAllowance] = useState({});
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const fetchColumns = [
        {
          name: `${t('S.No.')}`,
          width: '70px',
          wrap: true,
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
        },
        {
          name: `${t('Booking ID')}`,
          width: '200px',
          wrap: true,
          selector: row => <span className="fw-semibold cursor-pointer">{row?.bookingId}</span>,
        },
        {
          name: `${t('Name')}`,
          width: '170px',
          wrap: true,
          selector: row => row?.name,
        },
        {
          name: `${t('Bank Name')}`,
          width: '170px',
          wrap: true,
          selector: row => row?.bankName,
        },
        {
          name: `${t('Car Number')}`,
          width: '170px',
          wrap: true,
          selector: row => row?.carNumber,
        },
        {
          name: `${t('Date Time of Request')}`,
          width: '170px',
          wrap: true,
          selector: row => row?.dateOfRequest,
        },

        {
          name: `${t('Status')}`,
          width: '170px',
          wrap: true,
          selector: row => (
            <div className="">
              {row?.data?.vendor?.status === 'Completed' && <span className="booking_status break-word badge bg-completed">Accepted</span>}
              {row?.data?.vendor?.status === 'Confirmed' && !cancellationAllowance[row?.data.bookingId] && (
                <span className="booking_status break-word badge bg-completed">Accepted</span>
              )}
              {row?.data?.vendor?.status === 'Confirmed' && cancellationAllowance[row?.data.bookingId] && (
                <span className="booking_status break-word badge bg-confirmed">Confirmed</span>
              )}
              {row?.data?.vendor?.status === 'Rejected' && <span className="booking_status break-word badge bg-cancelled">Cancelled</span>}
            </div>
          ),
        },

        {
          name: `${t('Actions')}`,
          width: '200px',
          wrap: true,
          selector: row => (
            <div className="d-flex justify-content-center flex-column">
              <>
                {row?.orignalData?.vendor?.status === 'Confirmed' && cancellationAllowance[row?.orignalData.bookingId] && (
                  <button
                    className="btn btn-sm btn-primary mb-1"
                    onClick={() => {
                      actionBtnHandler(`Accept`, row?.orignalData);
                    }}
                    style={{ width: '150px' }}
                  >
                    {t('Accept')}
                  </button>
                )}
                {(row?.orignalData?.vendor?.status === 'Completed' || row?.orignalData?.vendor?.status === 'Confirmed') &&
                  cancellationAllowance[row?.orignalData.bookingId] && (
                    <button
                      className="btn btn-sm btn-danger mb-1"
                      onClick={() => {
                        actionBtnHandler(`Reject`, row?.orignalData);
                      }}
                      style={{ width: '150px' }}
                    >
                      {t('Cancel')}
                    </button>
                  )}
              </>
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);
      setBookingData(
        data?.map((bookingData, index) => {
          return {
            serialNumber: `${pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            bookingId: bookingData?.bookingId || `NA`,
            name: bookingData?.user?.name || `NA`,
            bankName: bankName?.completeData?.name,
            carNumber: bookingData?.car?.number || `NA`,
            dateOfRequest: bookingData?.createdAt ? moment(`${bookingData?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            data: bookingData,
            orignalData: { ...bookingData, bankCode: bankName?.completeData?.code },
          };
        }),
      );
    } else {
      setBookingData([]);
    }
  }, [cancellationAllowance]);

  useEffect(() => {
    if (data?.length > 0) {
      const updatedCancellationAllowance = {};
      data.forEach(booking => {
        // Check if the booking's creation time is valid
        if (booking?.createdAt) {
          const creationTime = new Date(booking.createdAt);
          const currentTime = new Date();
          const timeDifference = currentTime.getTime() - creationTime.getTime(); // Time difference in milliseconds

          // Allow cancellation if less than 10 minutes have passed since creation
          updatedCancellationAllowance[booking.bookingId] = timeDifference < 10 * 60 * 1000;
        }
      });
      setCancellationAllowance(updatedCancellationAllowance);
    }
  }, [data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    actionHandler(flag, data);
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <PreviewCardHeader title={t('Bookings Details')} />
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={bookingData ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                      padding: '8px',
                    },
                  },
                }}
              />
            </CardBody>
            {data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(String(pageNumber * Constant?.TABLE_PAGE_SIZE + 1))}</b> to&nbsp;
                      <b>{formattedWithComma(pageNumber * Constant?.TABLE_PAGE_SIZE + data?.length)}</b> of <b>{formattedWithComma(totalData)}</b>{' '}
                      records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={totalData} currentPage={pageNumber} onPageChangeHandler={pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(BookingTableLaptopScreen, (prevProps, nextProps) => prevProps.data === nextProps.data);
