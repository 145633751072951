import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Button, Modal, ModalBody, Col, Label, Input, Row } from 'reactstrap';

import { Error } from '../../../../../../Common';
import { isANumber } from '../../../../../../Helpers/Helpers';
import { Constant } from '../../../../../../Helpers/constant';
import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

const UpdateBookingModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    dropLocation: {
      nickName: '',
      address: '',
      emirates: '',
      region: '',
      zipCode: '',
    },
    pickupLocation: {
      nickName: '',
      address: '',
      emirates: '',
      region: '',
      zipCode: '',
    },
    dateTimeOfService: {
      date: '',
      time: '',
    },
    // pickupDate: '',
    // pickupTime: '',
    // bookingDateTime: '',
  };
  const [state, setState] = useState(initialObj);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    setState({
      // ...data,
      // dropLocation: data?.dropLocation,
      // pickupLocation: data?.pickupLocation,
      // dateTimeOfService: data?.dateTimeOfService,
      // bookingDateTime: data?.bookingDateTime?.replace('00Z', '00.000Z'),

      dropLocation: {
        address: data?.dropLocation?.address ?? ``,
        emirates: data?.dropLocation?.emirates ?? ``,
        nickName: data?.dropLocation?.nickName ?? ``,
        zipCode: data?.dropLocation?.zipCode ?? ``,
        region: data?.dropLocation?.region ?? ``,
      },
      pickupLocation: {
        address: data?.pickupLocation?.address ?? ``,
        emirates: data?.pickupLocation?.emirates ?? ``,
        nickName: data?.pickupLocation?.nickName ?? ``,
        zipCode: data?.pickupLocation?.zipCode ?? ``,
        region: data?.pickupLocation?.region ?? ``,
      },
      dateTimeOfService: {
        ...(data?.dateTimeOfService ?? ``),
      },
    });
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    let pickUpZipCodeTemp = ``;
    let dropUpZipCodeTemp = ``;

    switch (name) {
      case 'pickup-address':
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            address: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'pickup-emirates':
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            emirates: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'pickup-nickname':
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            nickName: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'pickup-region':
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            region: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'pickup-zipcode':
        if (value?.length === 0) {
          pickUpZipCodeTemp = ``;
        } else {
          pickUpZipCodeTemp = isANumber(value) ? value : state.pickupLocation?.zipCode;
        }
        updatedState = {
          ...state,
          pickupLocation: {
            ...state.pickupLocation,
            zipCode: pickUpZipCodeTemp,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'drop-address':
        updatedState = {
          ...state,
          dropLocation: {
            ...state.dropLocation,
            address: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'drop-emirates':
        updatedState = {
          ...state,
          dropLocation: {
            ...state.dropLocation,
            emirates: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'drop-nickname':
        updatedState = {
          ...state,
          dropLocation: {
            ...state.dropLocation,
            nickName: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'drop-region':
        updatedState = {
          ...state,
          dropLocation: {
            ...state.dropLocation,
            region: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'drop-zipcode':
        pickUpZipCodeTemp = ``;
        if (value?.length === 0) {
          pickUpZipCodeTemp = ``;
        } else {
          pickUpZipCodeTemp = isANumber(value) ? value : state.dropLocation?.zipCode;
        }
        updatedState = {
          ...state,
          dropLocation: {
            ...state.dropLocation,
            zipCode: pickUpZipCodeTemp,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'serviceTime':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            time: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'serviceDate':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            date: moment(new Date(date)).format('Y-MM-DD'),
          },
          // bookingDateTime: new Date(date).toISOString(),
          // pickupDate: moment(new Date(date)).format('Y-MM-DD'),
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'bookingDateTime':
        updatedState = {
          ...state,
          // bookingDateTime: new Date(date).toISOString(),
          bookingDateTime: moment(new Date(date)).format('Y-MM-DDTh:mm:ss.000'),
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                detailedBookingId: data?.detailedBookingId,
                pickupLocation: { ...state?.pickupLocation },
                dropLocation: { ...state?.dropLocation },
                dateTimeOfService: {
                  date: state.dateTimeOfService?.date,
                  time: state.dateTimeOfService?.time,
                },
                process: `update-booking`,

                // bookingDateTime: state?.bookingDateTime,
                // bookingDateTime: `${state?.bookingDateTime}Z`,
              },
            ],
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.pickupLocation?.address?.trim() !== `` &&
      data?.pickupLocation?.emirates?.trim() !== `` &&
      data?.pickupLocation?.nickName?.trim() !== `` &&
      data?.pickupLocation?.zipCode?.trim() !== `` &&
      data?.pickupLocation?.region?.trim() !== `` &&
      data?.dropLocation?.address?.trim() !== `` &&
      data?.dropLocation?.emirates?.trim() !== `` &&
      data?.dropLocation?.nickName?.trim() !== `` &&
      data?.dropLocation?.zipCode?.trim() !== `` &&
      data?.dropLocation?.region?.trim() !== `` &&
      data?.dateTimeOfService?.date !== `` &&
      data?.dateTimeOfService?.time !== `` &&
      moment() <= moment(state?.dateTimeOfService?.date)
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ state })}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="name" className="form-label">
                      Booking ID
                    </Label>
                    <Input className="form-control" value={data?.bookingId} disabled />
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="name" className="form-label">
                      User Name
                    </Label>
                    <Input className="form-control" value={data?.user?.name} disabled />
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <div>
                  <Label htmlFor="pickup-address" className="form-label">
                    Pickup Location Address
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="pickup-address"
                    name="pickup-address"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.pickupLocation?.address}
                  />
                </div>
                {isValidationShow && state?.pickupLocation?.address?.trim() === `` && <Error text="Kindly fill pickup location address" />}
              </Col>
            </div>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="pickup-emirates" className="form-label">
                    Pickup Location Emirates
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="pickup-emirates"
                    name="pickup-emirates"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.pickupLocation?.emirates}
                  />
                </div>
                {isValidationShow && state?.pickupLocation?.emirates?.trim() === `` && <Error text="Kindly fill pickup location emirates" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="pickup-nickname" className="form-label">
                    Pickup Location Nick Name
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="pickup-nickname"
                    name="pickup-nickname"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.pickupLocation?.nickName}
                  />
                </div>
                {isValidationShow && state?.pickupLocation?.nickName?.trim() === `` && <Error text="Kindly fill pickup location nick name" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="pickup-region" className="form-label">
                    Pickup Location Region
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="pickup-region"
                    name="pickup-region"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.pickupLocation?.region}
                  />
                </div>
                {isValidationShow && state?.pickupLocation?.region?.trim() === `` && <Error text="Kindly fill pickup location region" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="pickup-zipcode" className="form-label">
                    Pickup Location ZipCode
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="pickup-zipcode"
                    name="pickup-zipcode"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.pickupLocation?.zipCode}
                  />
                </div>
                {isValidationShow && state?.pickupLocation?.zipCode?.trim() === `` && <Error text="Kindly fill pickup location zipcode" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="drop-address" className="form-label">
                    Drop Location Address
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="drop-address"
                    name="drop-address"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.dropLocation?.address}
                  />
                </div>
                {isValidationShow && state?.dropLocation?.address?.trim() === `` && <Error text="Kindly fill drop location address" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="drop-emirates" className="form-label">
                    Drop Location Emirates
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="drop-emirates"
                    name="drop-emirates"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.dropLocation?.emirates}
                  />
                </div>
                {isValidationShow && state?.dropLocation?.emirates?.trim() === `` && <Error text="Kindly fill drop location emirates" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="drop-nickname" className="form-label">
                    Drop Location Nick Name
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="drop-nickname"
                    name="drop-nickname"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.dropLocation?.nickName}
                  />
                </div>
                {isValidationShow && state?.dropLocation?.nickName?.trim() === `` && <Error text="Kindly fill drop location nick name" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="drop-region" className="form-label">
                    Drop Location Region
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="drop-region"
                    name="drop-region"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.dropLocation?.region}
                  />
                </div>
                {isValidationShow && state?.dropLocation?.region?.trim() === `` && <Error text="Kindly fill drop location region" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="drop-zipcode" className="form-label">
                    Drop Location ZipCode
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="drop-zipcode"
                    name="drop-zipcode"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.dropLocation?.zipCode}
                  />
                </div>
                {isValidationShow && state?.dropLocation?.zipCode?.trim() === `` && <Error text="Kindly fill drop location zipcode" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Date of Service <span className="lbl_mandatory">*</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    name="serviceDate"
                    id="serviceDate"
                    value={state?.dateTimeOfService?.date}
                    onChange={date => datePickHandler(`serviceDate`, date)}
                    options={{
                      // minDate: 'today',
                      enableTime: false,
                      dateFormat: 'Y-m-d',
                    }}
                  />
                </div>
                {isValidationShow && state?.dateTimeOfService?.date === `` && <Error text="Kindly fill date of service" />}
                {isValidationShow && state?.dateTimeOfService?.date !== `` && moment() > moment(state?.dateTimeOfService?.date) && (
                  <Error text="Invalid date" />
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Time of Service <span className="lbl_mandatory">*</span>
                  </Label>
                  <input
                    type="time"
                    className="form-control"
                    id="serviceTime"
                    name="serviceTime"
                    onChange={inputChangeHandler}
                    value={state?.dateTimeOfService?.time}
                  />
                </div>
                {isValidationShow && state?.dateTimeOfService?.time === `` && <Error text="Kindly fill time of service" />}
              </div>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateBookingModal;
