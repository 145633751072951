import React, { useEffect, useState } from 'react';

import { Card, Col, Row, CardBody, Input, Label, CardHeader } from 'reactstrap';
import Select from 'react-select';
import { Constant } from '../../../../../../Helpers/constant';
import UserHistoryModal from '../UserHistoryModal';
import { Error, Loader } from './../../../../../../Common';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_USER_BOOKINGS,
  GET_USER_BOOKINGS_EMPTY,
  GET_USER_POINTS,
  GET_USER_POINTS_EMPTY,
  GET_WALLETS,
  GET_WALLETS_EMPTY,
} from '../../../../../../../store/application/actionType';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';
import moment from 'moment';
import * as XLSX from 'xlsx';

const ProjectStats = ({ data, filterDateHandler, monthName }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    caNumber: '',
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [isUserHistoryModalShow, setIsUserHistoryModalShow] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [misData, setMisData] = useState([]);
  const [bookingCategories, setBookingCategories] = useState([]);
  const [benefitClaims, setBenefitClaims] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // User Points
  const { userPoints, userPointsLoading } = useSelector(state => ({
    userPoints: state?.GetUserPoints?.data,
    userPointsLoading: state?.GetUserPoints?.loading,
  }));

  // User Bookings
  const { userBookings, userBookingsLoading } = useSelector(state => ({
    userBookings: state?.GetUserBookings?.data,
    userBookingsLoading: state?.GetUserBookings?.loading,
  }));

  // MIS REPORT
  const { misReportData } = useSelector(state => ({
    misReportData: state?.GetMisReport?.data,
  }));

  useEffect(() => {
    if (misReportData?.results?.length > 0) {
      setMisData(
        misReportData?.results?.map(data => ({
          month: data?.month,
          registrations: data?.registrations || '0',
          counts: data?.noOfBookings || '0',
        })) || [],
      );
    }
    if (data?.bookingReport?.benefitClaimCount?.length > 0) {
      setBenefitClaims(
        data?.bookingReport?.benefitClaimCount?.map(d => ({
          name: d?.entity?.name,
          counts: d?.count ?? 0,
        })) || [],
      );
    }
    if (data?.bookingReport?.bookingAcrossCategory?.length > 0) {
      setBookingCategories(
        data?.bookingReport?.bookingAcrossCategory?.map(d => ({
          name: d?.entity?.name,
          counts: d?.count ?? 0,
        })) || [],
      );
    }
  }, [misReportData, data?.bookingReport?.benefitClaimCount, data?.bookingReport?.bookingAcrossCategory]);

  useEffect(() => {
    if (userBookings !== null) {
      dispatch({
        type: GET_USER_POINTS,
        payload: {
          urlParam: {
            caNumber: parseInt(state?.caNumber),
          },
        },
      });
      setIsUserHistoryModalShow(true);
    }
  }, [userBookings]);

  useEffect(() => {
    return () => {
      setIsUserHistoryModalShow(false);
      setIsValidationShow(false);

      dispatch({
        type: GET_USER_POINTS_EMPTY,
      });
      dispatch({
        type: GET_USER_BOOKINGS_EMPTY,
      });
      dispatch({
        type: GET_WALLETS_EMPTY,
      });
    };
  }, []);

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'caNumber':
        updatedState = {
          ...state,
          caNumber: value?.length > 9 ? state?.caNumber : value,
        };
        break;
      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- VALIDATE ERROR ---------------------------- */
  const validateErrors = (flag, data = state) => {
    if (data?.caNumber?.trim()?.length === 9) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(`submissionCheck`, state)) {
      const today = new Date();
      const previousDate = new Date();
      previousDate.setDate(today.getDate() - 1); // Set to 7 days ago, adjust as needed

      const formattedToday = today.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD'
      const formattedPreviousDate = previousDate.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD'

      const dateRange = {
        from: formattedPreviousDate, // Previous date (e.g., 7 days ago)
        to: formattedToday, // Today's date
      };

      dispatch({
        type: GET_USER_BOOKINGS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            caNumber: parseInt(state?.caNumber),
            dateRange,
          },
        },
      });
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    dispatch({
      type: GET_USER_POINTS,
      payload: {
        urlParam: {
          caNumber: parseInt(state?.caNumber),
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (userPointsLoading || userBookingsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [userPointsLoading, userBookingsLoading]);

  const filterDateHandlerForUserBooking = date => {
    if (date.length === 2) {
      dispatch({
        type: GET_USER_BOOKINGS,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            caNumber: parseInt(state?.caNumber),
            dateRange: {
              from: moment(new Date(date[0])).format('Y-MM-DD'),
              to: moment(new Date(date[1])).format('Y-MM-DD'),
            },
          },
        },
      });
    }
  };

  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    // Helper function to append JSON as a table with headers and total price calculation
    const appendTableToSheet = (ws, jsonData, startRow, tableName, priceField) => {
      if (!jsonData || jsonData.length === 0) {
        // Handle empty data: Add table name and indicate no data available
        XLSX.utils.sheet_add_aoa(ws, [[`${tableName} (No Data Available)`]], { origin: startRow });
        return startRow + 2; // Skip two rows and return the updated row index
      }

      // Add table name as the title
      XLSX.utils.sheet_add_aoa(ws, [[tableName]], { origin: startRow });
      startRow += 1;

      // Add table headers and data
      const headers = Object.keys(jsonData[0]);
      const data = [headers, ...jsonData.map(item => Object.values(item))];
      XLSX.utils.sheet_add_aoa(ws, data, { origin: startRow });
      startRow += data.length;

      // Calculate total price if a price field exists
      if (priceField) {
        const totalPrice = jsonData.reduce((sum, item) => sum + (item[priceField] || 0), 0);
        XLSX.utils.sheet_add_aoa(ws, [[`Total ${priceField}:`, totalPrice]], { origin: startRow });
        startRow += 2;
      }

      return startRow;
    };

    const appendObjectToSheet = (ws, objData, startRow, title) => {
      if (!objData || Object.keys(objData).length === 0) {
        // Handle empty object data: Add title and indicate no data available
        XLSX.utils.sheet_add_aoa(ws, [[`${title} (No Data Available)`]], { origin: startRow });
        return startRow + 2; // Skip two rows and return the updated row index
      }

      // Add title for key-value section
      XLSX.utils.sheet_add_aoa(ws, [[title]], { origin: startRow });
      startRow += 1;

      // Convert the object to key-value format and append
      const data = Object.entries(objData);
      XLSX.utils.sheet_add_aoa(ws, data, { origin: startRow });
      startRow += data.length + 2;

      return startRow;
    };

    // Append tables one by one
    let nextRow = 0;
    nextRow = appendTableToSheet(worksheet, bookingCategories, nextRow, 'Table 1: Category Points Redemption', 'counts');
    nextRow = appendTableToSheet(worksheet, benefitClaims, nextRow, 'Table 2: Partner Points Redemption', 'counts');
    nextRow = appendTableToSheet(worksheet, misData, nextRow, 'Table 3: Monthly Registration', 'counts');

    // Object data you want to append
    const keyValueData = {
      'Total Users:': data?.totalUsers ?? 0,
      'Total Wallet Balance:': data?.totalWalletBalance ?? 0,
      'No. of rewards claimed:': data?.noOfRewardsClaimed ?? 0,
      'Unique User Rewards Claimed:': data?.uniqueUserRewards ?? 0,
      'Average Points Per Unique User:': data?.pointsPerUser ?? 0,
      'Total coins received:': data?.totalIncoming ?? 0,
      'Total coins spent:': data?.totalOutgoingCoins ?? 0,
      'Total Coins Expired:': data?.data?.totalCoinsExpired ?? 0,
    };

    // Append key-value object after the tables
    nextRow = appendObjectToSheet(worksheet, keyValueData, nextRow, 'Table 4: Program Summary');

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Generate and download the Excel file
    XLSX.writeFile(workbook, `Summary_BRPL_${monthName}.xlsx`);
  };

  return (
    <>
      {loading && <Loader zIndex={true} />}
      {isUserHistoryModalShow && (
        <UserHistoryModal
          userPoints={userPoints}
          userBookings={userBookings}
          onCloseHandler={() => setIsUserHistoryModalShow(false)}
          pageNumber={currentPageNumber}
          pageHandler={paginationHandler}
          filterDateHandler={filterDateHandlerForUserBooking}
        />
      )}

      {/* <Card className="card-inner">
        <CardBody>
          <Row>
            <Col md={4}>
              <Label htmlFor="programId" className="form-label">
                Program Name
              </Label>
              <Select
                id="programId"
                name="programId"
                isDisabled={true}
                value={{
                  value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
                  label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
                }}
                // options={projects?.results?.map((project) => ({
                //     value: project?.id,
                //     label: project?.name,
                // }))}
                // onChange={(data) => inputSelectHandler(`programId`, data)}
              />
            </Col>

            <Col md={4}>
              <Label htmlFor="Wallet" className="form-label">
                Wallet
              </Label>
              <Select
                id="Wallet"
                name="Wallet"
                // isDisabled={true}
                value={{
                  value: Constant?.CURRENT_SELECTED_PROGRAM?.id,
                  label: Constant?.CURRENT_SELECTED_PROGRAM?.name,
                }}
                // options={projects?.results?.map((project) => ({
                //     value: project?.id,
                //     label: project?.name,
                // }))}
                // onChange={(data) => inputSelectHandler(`programId`, data)}
              />
            </Col>

            <Col md={4} className="align-self-end">
              <button type="button" className="btn btn-primary" disabled>
                Apply
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card> */}

      <Card className="card-inner">
        <CardHeader>
          <h5>User History</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <div>
                <Label htmlFor="validityTatValue" className="form-label">
                  CA Number
                </Label>
                <Input
                  id="caNumber"
                  name="caNumber"
                  type="number"
                  className="form-control"
                  onChange={inputChangeHandler}
                  value={state?.caNumber}
                  autoComplete="off"
                />
              </div>
              {isValidationShow && state?.caNumber?.trim()?.length !== 9 ? <Error text="Please enter CA number" /> : <span>&nbsp;</span>}
            </Col>

            <Col md={4} className="align-self-center mt-2">
              <button type="button" className="btn btn-primary" onClick={submitHandler}>
                Submit
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="card-inner">
        <CardHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h5>Program Summary</h5>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <span className="btn btn-success" onClick={downloadExcel}>
                <i className="ri-download-line me-1 text-white fs-14 align-middle"></i>Download Dump
              </span>
              <Flatpickr
                className="form-control"
                style={{ width: '120px' }}
                onChange={filterDateHandler}
                value={`${moment().startOf('month').format('YYYY-MM-DD')}`}
                options={{
                  maxDate: moment().endOf('month').format('YYYY-MM-DD'),
                  plugins: [
                    new monthSelectPlugin({
                      shorthand: true,
                      dateFormat: 'Y-m',
                      altFormat: 'F Y',
                      theme: 'dark',
                    }),
                  ],
                }}
                placeholder="Select Month"
              />
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <Row>
            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-user-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total Users:</p>
                    <h5 className="mb-0">{data?.totalUsers ?? '0'}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-wallet-3-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total Wallet Balance:</p>
                    <h5 className="mb-0">
                      <span className="text-warning fs-20">
                        <i className="lab las la-coins"></i>
                      </span>
                      {data?.totalWalletBalance ?? '0'}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-ticket-2-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">No. of rewards claimed:</p>
                    <h5 className="mb-0">{data?.noOfRewardsClaimed ?? '0'}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-map-pin-user-line"></i>{' '}
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Unique User Rewards Claimed:</p>
                    <h5 className="mb-0">
                      <span className="text-warning fs-20">
                        <i className="lab las la-coins"></i>
                      </span>
                      {data?.uniqueUserRewards ?? '0'}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-map-pin-user-line"></i>{' '}
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Average Points Per Unique User:</p>
                    <h5 className="mb-0">
                      <span className="text-warning fs-20">
                        <i className="lab las la-coins"></i>
                      </span>
                      {data?.pointsPerUser ?? '0'}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-ticket-2-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total coins received:</p>
                    <h5 className="mb-0">{data?.totalIncoming ?? '0'}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className="ri-ticket-2-line"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total coins spent:</p>
                    <h5 className="mb-0">{data?.totalOutgoingCoins ?? '0'}</h5>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3}>
              <div className="p-2 my-1 border border-dashed rounded bg-white">
                <div className="d-flex align-items-center">
                  <div className="avatar-sm me-2">
                    <div className="avatar-title rounded bg-transparent text-primary fs-24">
                      <i className=" ri-delete-back-2-line"></i>{' '}
                    </div>
                  </div>
                  <div className="flex-grow-1 text-end">
                    <p className="text-muted mb-1">Total Coins Expired:</p>
                    <h5 className="mb-0">
                      <span className="text-warning fs-20">
                        <i className="lab las la-coins"></i>
                      </span>
                      {data?.totalCoinsExpired ?? '0'}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ProjectStats;
