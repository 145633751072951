import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, Input } from 'reactstrap';
import { GET_SERVICE, GET_SERVICE_EMPTY, UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../../Helpers/constant';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

const UpdateModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [bookings, setBookings] = useState([]);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(
        props?.data.map(booking => {
          return {
            ...booking,
          };
        }),
      );
    }
  }, []);

  useEffect(() => {
    validationCheck(bookings);
  }, [bookings]);

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;

    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.facility?.id === '') {
        flag = true;
        break;
      } else if (data[i]?.dateTimeOfService?.time === '') {
        flag = true;
        break;
      } else if (data[i]?.service?.id === '') {
        flag = true;
        break;
      }
    }

    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        dateTimeOfService: {
          date: booking?.dateTimeOfService?.date,
          time: booking?.dateTimeOfService?.time,
        },
        service: {
          id: booking?.service?.id,
          name: booking?.service?.name,
        },
        process: `update-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.golfBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const datePickHandler = (flag, date, index) => {
    let tempBooking = [];
    let selectedChangingBooking = null;
    const selectedTime = date[0]; // Extract the first (and only) date object from the array
    const value = selectedTime
      ? `${selectedTime.getHours().toString().padStart(2, '0')}:${selectedTime.getMinutes().toString().padStart(2, '0')}`
      : ''; // Extract hours and minutes as a string with leading zeros
    switch (flag) {
      case 'time-of-play':
        tempBooking = [...bookings];
        selectedChangingBooking = {
          ...tempBooking[index],
          dateTimeOfService: {
            ...tempBooking[index]?.dateTimeOfService,
            time: value,
          },
        };
        tempBooking?.splice(index, 1, selectedChangingBooking);
        validationCheck(tempBooking);
        setBookings(tempBooking);
        break;

      default:
      // do nothing
    }
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log(bookings)}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Golf Course</th>
                    <th> Service</th>
                    <th> Time of Play </th>
                  </tr>
                </thead>
                <tbody>
                  {bookings?.map((booking, index) => (
                    <tr key={index} style={{ marginBottom: '10px' }}>
                      <th>{index + 1}.</th>
                      <td>{booking?.bookingId}</td>
                      <td>{booking?.user?.name}</td>

                      <td>
                        {
                          // <select
                          //   id="golf-course"
                          //   name="golf-course"
                          //   className="form-select"
                          //   value={booking?.facility?.id}
                          //   onChange={e => inputChangeHandler(e, index)}
                          // >
                          //   <option value="">Choose...</option>
                          //   {props.locations?.results?.map((location, index) => (
                          //     <option key={index} value={location?.objectId}>
                          //       {location?.displayName}
                          //     </option>
                          //   ))}
                          // </select>
                          <Select
                            id="facility"
                            name="facility"
                            value={{ value: booking?.facility?.name, label: booking?.facility?.name, data: booking?.facility }}
                            isDisabled={true}
                          />
                        }
                      </td>

                      <td>
                        {
                          // <select
                          //   id="service"
                          //   name="service"
                          //   className="form-select"
                          //   value={booking?.service?.id}
                          //   onChange={e => inputChangeHandler(e, index)}
                          // >
                          //   <option value="">Choose...</option>
                          //   {allServicesArray?.[index]?.map((service, i) => (
                          //     <option key={i} value={service?.objectId}>
                          //       {service?.name}
                          //     </option>
                          //   ))}
                          // </select>
                          <Select
                            id="service"
                            name="service"
                            value={{ value: booking?.service?.name, label: booking?.service?.name, data: booking?.service }}
                            isDisabled={true}
                          />
                        }
                      </td>

                      <td>
                        {
                          <Flatpickr
                            className={`form-control`}
                            name={`time-of-play`}
                            id="time-of-play"
                            value={booking?.dateTimeOfService?.time}
                            onChange={date => datePickHandler(`time-of-play`, date, index)}
                            options={{
                              // minDate: 'today',
                              noCalendar: true,
                              enableTime: true,
                              dateFormat: 'H:i',
                              time_24hr: false,
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateModal;
