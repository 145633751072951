import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Row, Col, Button, Modal, ModalBody, ModalHeader, Table, Input } from 'reactstrap';
import { GET_SERVICE, GET_SERVICE_EMPTY, UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../../Helpers/constant';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

const UpdateModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */

  const [bookings, setBookings] = useState([]);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(
        props?.data.map(booking => {
          return {
            ...booking,
          };
        }),
      );
    }
  }, []);

  useEffect(() => {
    validationCheck(bookings);
  }, [bookings]);

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;

    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.pickupLocation?.address === '') {
        flag = true;
        break;
      } else if (data[i]?.pickupLocation?.emirates === '') {
        flag = true;
        break;
      } else if (data[i]?.pickupLocation?.region === '') {
        flag = true;
        break;
      } else if (data[i]?.dateTimeOfService?.date === '') {
        flag = true;
        break;
      } else if (data[i]?.dateTimeOfService?.time === '') {
        flag = true;
        break;
      }
    }

    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        detailedBookingId: booking?.detailedBookingId,
        pickupLocation: booking?.pickupLocation,
        dateTimeOfService: {
          date: booking?.dateTimeOfService?.date,
          time: booking?.dateTimeOfService?.time,
        },
        dropLocation: booking?.dropLocation,
        process: `update-booking`,
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.golfBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const datePickHandler = (flag, date, index) => {
    let tempBooking = [...bookings];
    let selectedChangingBooking = null;

    const selectedTime = date[0]; // Extract the first (and only) date object from the array
    const value = selectedTime
      ? `${selectedTime.getHours().toString().padStart(2, '0')}:${selectedTime.getMinutes().toString().padStart(2, '0')}`
      : ''; // Extract hours and minutes as a string with leading zeros

    switch (flag) {
      case 'serviceTime':
        selectedChangingBooking = {
          ...tempBooking[index],
          dateTimeOfService: {
            ...tempBooking[index]?.dateTimeOfService,
            time: value,
          },
        };
        break;

      case 'serviceDate':
        selectedChangingBooking = {
          ...tempBooking[index],
          dateTimeOfService: {
            ...tempBooking[index]?.dateTimeOfService,
            date: moment(new Date(date)).format('Y-MM-DD'),
          },
        };

      default:
      // do nothing
    }
    tempBooking?.splice(index, 1, selectedChangingBooking);
    validationCheck(tempBooking);
    setBookings(tempBooking);
  };

  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;
    let tempBookings = [...bookings];
    let selectedChangingBooking = tempBookings[index];

    switch (name) {
      case 'pickup-address':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          pickupLocation: {
            ...selectedChangingBooking?.pickupLocation,
            address: value,
          },
        };
        break;

      case 'pickup-emirates':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          pickupLocation: {
            ...selectedChangingBooking?.pickupLocation,
            emirates: value,
          },
        };
        break;

      case 'pickup-region':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          pickupLocation: {
            ...selectedChangingBooking?.pickupLocation,
            region: value,
          },
        };
        break;
      default:
      // do nothing
    }

    tempBookings.splice(index, 1, selectedChangingBooking);
    setBookings(tempBookings);
    validationCheck(tempBookings);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log(bookings)}>get state</button> */}
        <form>
          <Row>
            <Col md={12} style={{ mixHeight: '500px' }}>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th> Name</th>
                    <th> Pickup Address</th>
                    <th> Pickup Emirates</th>
                    <th> Pickup Region </th>
                    <th> Date of Service </th>
                    <th> Time of Service </th>
                  </tr>
                </thead>
                <tbody>
                  {bookings?.map((booking, index) => (
                    <tr key={index} style={{ marginBottom: '10px' }}>
                      <th>{index + 1}.</th>
                      <td>{booking?.bookingId}</td>
                      <td>{booking?.user?.name}</td>

                      <td>
                        <Input
                          id="pickup-address"
                          name="pickup-address"
                          type="text"
                          style={{ width: '200px' }}
                          placeholder=""
                          className="form-control"
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.pickupLocation?.address}
                        />
                      </td>

                      <td>
                        <Input
                          id="pickup-emirates"
                          name="pickup-emirates"
                          type="text"
                          placeholder=""
                          style={{ width: '200px' }}
                          className="form-control"
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.pickupLocation?.emirates}
                        />
                      </td>

                      <td>
                        <Input
                          id="pickup-region"
                          name="pickup-region"
                          type="text"
                          placeholder=""
                          style={{ width: '200px' }}
                          className="form-control"
                          onChange={e => inputChangeHandler(e, index)}
                          value={booking?.pickupLocation?.region}
                        />
                      </td>
                      <td>
                        <Flatpickr
                          className="form-control"
                          name="serviceDate"
                          id="serviceDate"
                          style={{ width: '200px' }}
                          value={booking?.dateTimeOfService?.date}
                          onChange={date => datePickHandler(`serviceDate`, date, index)}
                          options={{
                            enableTime: false,
                            dateFormat: 'Y-m-d',
                          }}
                        />
                      </td>

                      <td>
                        {
                          <Flatpickr
                            className={`form-control`}
                            name={`serviceTime`}
                            id="serviceTime"
                            style={{ width: '200px' }}
                            value={booking?.dateTimeOfService?.time}
                            onChange={date => datePickHandler(`serviceTime`, date, index)}
                            options={{
                              noCalendar: true,
                              enableTime: true,
                              dateFormat: 'H:i',
                              time_24hr: false,
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateModal;
