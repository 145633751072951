import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { Constant } from '../../../../../../Helpers/constant';
import { Error } from '../../../../../../Common';

import { Button, Modal, ModalBody, Col, Label, Input, Row } from 'reactstrap';

import { UPDATE_BOOKING } from '../../../../../../../store/application/actionType';

import { isANumber } from '../../../../../../Helpers/Helpers';

const UpdateBookingModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    courierDropAddress: {
      emirates: {
        location: '',
      },
      fullAddress: {
        address: '',
      },
      region: {
        location: '',
      },
      fullName: '',
      landline: '',
      landmark: '',
      mobile: '',
    },
    courierPickupAddress: {
      emirates: {
        location: '',
      },
      fullAddress: {
        address: '',
      },
      region: {
        location: '',
      },
      fullName: '',
      landline: '',
      landmark: '',
      mobile: '',
    },
    dateTimeOfService: {
      date: '',
      time: '',
    },
    // bookingDateTime: '',
  };
  const [state, setState] = useState(initialObj);

  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isValidationShow, setIsValidationShow] = useState(false);

  useEffect(() => {
    setState({
      courierDropAddress: {
        emirates: {
          location: data?.courierDropAddress?.emirates?.location ?? '',
        },
        fullAddress: {
          address: data?.courierDropAddress?.fullAddress?.address ?? '',
        },
        region: {
          location: data?.courierDropAddress?.region?.location ?? '',
        },
        fullName: data?.courierDropAddress?.fullName ?? '',
        landline: data?.courierDropAddress?.landline ?? '',
        landmark: data?.courierDropAddress?.landmark ?? '',
        mobile: data?.courierDropAddress?.mobile ?? '',
      },
      courierPickupAddress: {
        emirates: {
          location: data?.courierPickupAddress?.emirates?.location ?? '',
        },
        fullAddress: {
          address: data?.courierPickupAddress?.fullAddress?.address ?? '',
        },
        region: {
          location: data?.courierPickupAddress?.region?.location ?? '',
        },
        fullName: data?.courierPickupAddress?.fullName ?? '',
        landline: data?.courierPickupAddress?.landline ?? '',
        landmark: data?.courierPickupAddress?.landmark ?? '',
        mobile: data?.courierPickupAddress?.mobile ?? '',
      },
      dateTimeOfService: {
        date: data?.dateTimeOfService?.date,
        time: data?.dateTimeOfService?.time,
      },
      // bookingDateTime: data?.bookingDateTime?.replace('00Z', '00.000Z'),
    });
  }, [data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    let courierPickupLandline = '';
    let courierPickupMobile = '';
    let courierDropLandline = '';
    let courierDropMobile = '';

    switch (name) {
      case 'courier-pickup-emirates':
        updatedState = {
          ...state,
          courierPickupAddress: {
            ...state.courierPickupAddress,
            emirates: {
              location: value,
            },
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-pickup-address':
        updatedState = {
          ...state,
          courierPickupAddress: {
            ...state.courierPickupAddress,
            fullAddress: {
              address: value,
            },
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-pickup-fullname':
        updatedState = {
          ...state,
          courierPickupAddress: {
            ...state.courierPickupAddress,
            fullName: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-pickup-landline':
        if (value?.length === 0) {
          courierPickupLandline = ``;
        } else {
          courierPickupLandline = isANumber(value) ? value : state?.courierPickupAddress?.landline;
        }
        updatedState = {
          ...state,
          courierPickupAddress: {
            ...state.courierPickupAddress,
            landline: courierPickupLandline,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-pickup-landmark':
        updatedState = {
          ...state,
          courierPickupAddress: {
            ...state.courierPickupAddress,
            landmark: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-pickup-mobile':
        if (value?.length === 0) {
          courierPickupMobile = ``;
        } else {
          courierPickupMobile = isANumber(value) ? value : state?.courierPickupAddress?.mobile;
        }
        updatedState = {
          ...state,
          courierPickupAddress: {
            ...state.courierPickupAddress,
            mobile: courierPickupMobile,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-pickup-region':
        updatedState = {
          ...state,
          courierPickupAddress: {
            ...state.courierPickupAddress,
            region: {
              location: value,
            },
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      // -----------
      case 'courier-dropup-emirates':
        updatedState = {
          ...state,
          courierDropAddress: {
            ...state.courierDropAddress,
            emirates: {
              location: value,
            },
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-dropup-address':
        updatedState = {
          ...state,
          courierDropAddress: {
            ...state.courierDropAddress,
            fullAddress: {
              address: value,
            },
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-dropup-fullname':
        updatedState = {
          ...state,
          courierDropAddress: {
            ...state.courierDropAddress,
            fullName: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-dropup-landline':
        if (value?.length === 0) {
          courierDropLandline = ``;
        } else {
          courierDropLandline = isANumber(value) ? value : state?.courierDropAddress?.landline;
        }
        updatedState = {
          ...state,
          courierDropAddress: {
            ...state.courierDropAddress,
            landline: courierDropLandline,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-dropup-landmark':
        updatedState = {
          ...state,
          courierDropAddress: {
            ...state.courierDropAddress,
            landmark: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-dropup-mobile':
        if (value?.length === 0) {
          courierDropMobile = ``;
        } else {
          courierDropMobile = isANumber(value) ? value : state?.courierDropAddress?.mobile;
        }
        updatedState = {
          ...state,
          courierDropAddress: {
            ...state.courierDropAddress,
            mobile: courierDropMobile,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'courier-dropup-region':
        updatedState = {
          ...state,
          courierDropAddress: {
            ...state.courierDropAddress,
            region: {
              location: value,
            },
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'serviceTime':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            time: value,
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  const datePickHandler = (flag, date) => {
    let updatedState = null;

    switch (flag) {
      case 'serviceDate':
        updatedState = {
          ...state,
          dateTimeOfService: {
            ...state?.dateTimeOfService,
            date: moment(new Date(date)).format('Y-MM-DD'),
          },
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      // case 'bookingDateTime':
      //   updatedState = {
      //     ...state,
      //     bookingDateTime: new Date(date).toISOString(),
      //   }
      //   setState(updatedState)
      //   validationCheck(updatedState)
      //   break

      default:
      // do nothing
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: UPDATE_BOOKING,
        payload: {
          data: {
            projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
            bookingTypeId: Constant?.BOOKING_TYPES?.localCourierBookings?.objectId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                detailedBookingId: data?.detailedBookingId,
                courierDropAddress: state?.courierDropAddress,
                courierPickupAddress: state?.courierPickupAddress,
                dateTimeOfService: {
                  date: state?.dateTimeOfService?.date,
                  time: state?.dateTimeOfService?.time,
                },
                process: `update-booking`,
                // bookingDateTime: state?.bookingDateTime,
              },
            ],
          },
        },
      });

      onCloseHandler(false);
      setmodal_varying1(!modal_varying1);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data?.courierPickupAddress?.emirates?.location?.trim() !== `` &&
      data?.courierPickupAddress?.fullAddress?.address?.trim() !== `` &&
      data?.courierPickupAddress?.fullName?.trim() !== `` &&
      data?.courierPickupAddress?.landline?.trim() !== `` &&
      data?.courierPickupAddress?.landmark?.trim() !== `` &&
      data?.courierPickupAddress?.mobile?.trim() !== `` &&
      data?.courierPickupAddress?.region?.location?.trim() !== `` &&
      data?.courierDropAddress?.emirates?.location?.trim() !== `` &&
      data?.courierDropAddress?.fullAddress?.address?.trim() !== `` &&
      data?.courierDropAddress?.fullName?.trim() !== `` &&
      data?.courierDropAddress?.landline?.trim() !== `` &&
      data?.courierDropAddress?.landmark?.trim() !== `` &&
      data?.courierDropAddress?.mobile?.trim() !== `` &&
      data?.courierDropAddress?.region?.location?.trim() !== `` &&
      data?.dateTimeOfService?.date !== `` &&
      data?.dateTimeOfService?.time !== `` &&
      moment() <= moment(state?.dateTimeOfService?.date)
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      scrollable={true}
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ state })}>Get state</button> */}
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Booking ID
                  </Label>
                  <Input className="form-control" value={data?.bookingId} disabled />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    User Name
                  </Label>
                  <Input className="form-control" value={data?.user?.name} disabled />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-pickup-emirates" className="form-label">
                    Courier Pickup emirates
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-pickup-emirates"
                    name="courier-pickup-emirates"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierPickupAddress?.emirates?.location}
                  />
                </div>
                {isValidationShow && state?.courierPickupAddress?.emirates?.location?.trim() === `` && (
                  <Error text="Kindly fill courier pickup emirates" />
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-pickup-address" className="form-label">
                    Courier Pickup Address
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-pickup-address"
                    name="courier-pickup-address"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierPickupAddress?.fullAddress?.address}
                  />
                </div>
                {isValidationShow && state?.courierPickupAddress?.fullAddress?.address?.trim() === `` && (
                  <Error text="Kindly fill courier pickup address" />
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-pickup-fullname" className="form-label">
                    Courier Pickup Name
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-pickup-fullname"
                    name="courier-pickup-fullname"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierPickupAddress?.fullName}
                  />
                </div>
                {isValidationShow && state?.courierPickupAddress?.fullName?.trim() === `` && <Error text="Kindly fill courier pickup name" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-pickup-landline" className="form-label">
                    Courier Pickup Landline
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-pickup-landline"
                    name="courier-pickup-landline"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierPickupAddress?.landline}
                  />
                </div>
                {isValidationShow && state?.courierPickupAddress?.landline?.trim() === `` && <Error text="Kindly fill courier pickup landline" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-pickup-landmark" className="form-label">
                    Courier Pickup LandMark
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-pickup-landmark"
                    name="courier-pickup-landmark"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierPickupAddress?.landmark}
                  />
                </div>
                {isValidationShow && state?.courierPickupAddress?.landmark?.trim() === `` && <Error text="Kindly fill courier pickup landmark" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-pickup-mobile" className="form-label">
                    Courier Pickup Mobile
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-pickup-mobile"
                    name="courier-pickup-mobile"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierPickupAddress?.mobile}
                  />
                </div>
                {isValidationShow && state?.courierPickupAddress?.mobile?.trim() === `` && <Error text="Kindly fill courier pickup mobile" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-pickup-region" className="form-label">
                    Courier Pickup Region
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-pickup-region"
                    name="courier-pickup-region"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierPickupAddress?.region?.location}
                  />
                </div>
                {isValidationShow && state?.courierPickupAddress?.region?.location?.trim() === `` && (
                  <Error text="Kindly fill courier pickup region" />
                )}
              </div>
            </Col>

            {/* ----------------- */}
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-dropup-emirates" className="form-label">
                    Courier Drop emirates
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-dropup-emirates"
                    name="courier-dropup-emirates"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierDropAddress?.emirates?.location}
                  />
                </div>
                {isValidationShow && state?.courierDropAddress?.emirates?.location?.trim() === `` && (
                  <Error text="Kindly fill courier drop emirates" />
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-dropup-address" className="form-label">
                    Courier Drop Address
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-dropup-address"
                    name="courier-dropup-address"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierDropAddress?.fullAddress?.address}
                  />
                </div>
                {isValidationShow && state?.courierDropAddress?.fullAddress?.address?.trim() === `` && (
                  <Error text="Kindly fill courier drop address" />
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-dropup-fullname" className="form-label">
                    Courier Drop Name
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-dropup-fullname"
                    name="courier-dropup-fullname"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierDropAddress?.fullName}
                  />
                </div>
                {isValidationShow && state?.courierDropAddress?.fullName?.trim() === `` && <Error text="Kindly fill courier drop name" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-dropup-landline" className="form-label">
                    Courier Drop Landline
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-dropup-landline"
                    name="courier-dropup-landline"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierDropAddress?.landline}
                  />
                </div>
                {isValidationShow && state?.courierDropAddress?.landline?.trim() === `` && <Error text="Kindly fill courier drop landline" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-dropup-landmark" className="form-label">
                    Courier Drop LandMark
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-dropup-landmark"
                    name="courier-dropup-landmark"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierDropAddress?.landmark}
                  />
                </div>
                {isValidationShow && state?.courierDropAddress?.landmark?.trim() === `` && <Error text="Kindly fill courier drop landmark" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-dropup-mobile" className="form-label">
                    Courier Drop Mobile
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-dropup-mobile"
                    name="courier-dropup-mobile"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierDropAddress?.mobile}
                  />
                </div>
                {isValidationShow && state?.courierDropAddress?.mobile?.trim() === `` && <Error text="Kindly fill courier drop mobile" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="courier-dropup-region" className="form-label">
                    Courier Drop Region
                    <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input
                    id="courier-dropup-region"
                    name="courier-dropup-region"
                    type="text"
                    placeholder=""
                    className="form-control"
                    onChange={inputChangeHandler}
                    value={state?.courierDropAddress?.region?.location}
                  />
                </div>
                {isValidationShow && state?.courierDropAddress?.region?.location?.trim() === `` && <Error text="Kindly fill courier drop region" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Date of Service <span className="lbl_mandatory">*</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    name="serviceDate"
                    id="serviceDate"
                    value={state?.dateTimeOfService?.date}
                    onChange={date => datePickHandler(`serviceDate`, date)}
                    options={{
                      enableTime: false,
                      dateFormat: 'Y-m-d',
                    }}
                  />
                </div>
                {isValidationShow && state?.dateTimeOfService?.date === `` && <Error text="Kindly fill date of service" />}
                {isValidationShow && state?.dateTimeOfService?.date !== `` && moment() > moment(state?.dateTimeOfService?.date) && (
                  <Error text="Invalid date" />
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Time of Service <span className="lbl_mandatory">*</span>
                  </Label>
                  <input
                    type="time"
                    className="form-control"
                    id="serviceTime"
                    name="serviceTime"
                    onChange={inputChangeHandler}
                    value={state?.dateTimeOfService?.time}
                  />
                </div>
                {isValidationShow && state?.dateTimeOfService?.time === `` && <Error text="Kindly fill time of service" />}
              </div>
            </Col>

            {/* <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="coupon-remarks" className="form-label">
                    Booking Date & Time <span className="lbl_mandatory">*</span>
                  </Label>
                  <Flatpickr
                    className="form-control"
                    name="expiry-date"
                    id="expiry-date"
                    value={state?.bookingDateTime}
                    onChange={(date) => 
                      datePickHandler(`bookingDateTime`, date)
                    }
                    options={{
                      minDate: 'today',
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }}
                  />
                </div>
              </Col>
            </div> */}
          </Row>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateBookingModal;
