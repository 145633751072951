export enum makerCheckerEnum {
  DOWNLOAD_MEMBERSHIP = 'download_membership',
}

enum viewStyleEnum {
  card = 1,
}

export const makerCheckerConfigs = {
  [makerCheckerEnum?.DOWNLOAD_MEMBERSHIP]: {
    requestType: makerCheckerEnum?.DOWNLOAD_MEMBERSHIP,
    viewStyle: viewStyleEnum?.card,
  },
};
