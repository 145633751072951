import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalBody, Input, Label, Col } from 'reactstrap';
import { Constant } from '../../../../../../../Helpers/constant';

import { CONFIRM_BOOKING_V2, UPDATE_BOOKING } from '../../../../../../../../store/actions';
import { Error } from '../../../../../../../Common';
import { isANumberWithDecimal, validateNumber } from '../../../../../../../Helpers/Helpers';

const ConfirmModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    price: '',
    carType: '',
    remarks: '',
    vendorId: data?.vendorId,
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);

  // Confirm Booking
  const { confirmBooking } = useSelector(state => ({
    confirmBooking: state?.ConfirmBookingV2?.data,
  }));

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    let vendorPrice = '';

    switch (name) {
      case 'price':
        if (value?.length === 0) {
          vendorPrice = ``;
        } else {
          vendorPrice = isANumberWithDecimal(value) ? value : state?.price;
        }
        updatedState = {
          ...state,
          price: vendorPrice,
        };

        break;

      case 'car-type':
        updatedState = { ...state, carType: value };
        break;

      case 'vendor-remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.price?.trim() !== `` && data?.carType?.trim() !== `` && data?.remarks?.trim() !== `` && validateNumber(data?.price)) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: CONFIRM_BOOKING_V2,
        payload: {
          data: {
            airport_transfer_booking_request: {
              vendor_car_type: Number(state.carType),
              vendor_price: Number(state.price),
              vendor_remarks: state.remarks,
            },
            booking_id: data?.booking_id,
            service_id: data?.service_id,
            membership_id: data?.membership_id,
            benefit_group_config_id: String(data?.benefit_group_config_id),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (confirmBooking !== null) {
      onCloseHandler(false);
    }
  }, [confirmBooking]);

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        onCloseHandler();
      }}
      id="accept-bookings-modal"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Confirm Booking
        </h5>
        <Button
          type="button"
          onClick={() => {
            onCloseHandler();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <form>
          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="price" className="form-label">
                  Vendor Price <span className="lbl_mandatory">*</span>
                </Label>
                <Input id="price" name="price" type="text" className="form-control" value={state?.price} onChange={inputChangeHandler} />
              </div>
              {isValidationShow && state?.price?.trim() === `` && <Error text="Please enter price" />}
              {isValidationShow && state?.price?.trim() !== `` && !validateNumber(state?.price?.trim()) && <Error text="Please enter valid price" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="car-type" className="form-label">
                  Vendor Car Type <span className="lbl_mandatory">*</span>
                </Label>
                <select id="car-type" name="car-type" className="form-select" value={state?.carType} onChange={inputChangeHandler}>
                  <option value="" disabled>
                    Choose...
                  </option>
                  <option value="1">Luxury</option>
                  <option value="2">Sedan</option>
                  <option value="3">MVP</option>
                </select>
              </div>
              {isValidationShow && state?.carType === `` && <Error text="Please select car type" />}
            </div>
          </Col>

          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="vendor-remarks" className="form-label">
                  Vendor Remarks <span className="lbl_mandatory">*</span>
                </Label>
                <Input
                  id="vendor-remarks"
                  name="vendor-remarks"
                  type="text"
                  className="form-control"
                  value={state?.remarks}
                  onChange={inputChangeHandler}
                />
              </div>
              {isValidationShow && state?.remarks?.trim() === `` && <Error text="Please enter remarks" />}
            </div>
          </Col>
        </form>
      </ModalBody>
      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="danger" onClick={() => onCloseHandler()}>
          Close
        </Button>
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
