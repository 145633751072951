import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Flatpickr from 'react-flatpickr';
import { Row, Col, Button, Modal, ModalBody, Table, ModalFooter, Input } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import {
  isANumber,
  isANumberWithDecimal,
  isValidAlphaNumberic,
  isValidAlphaNumbericWithoutSpace,
  isValidAplha,
} from '../../../../../../../Helpers/Helpers';

const UpdateModal = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [bookings, setBookings] = useState([]);
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (props?.data !== null) {
      setBookings(
        props?.data?.map(booking => {
          return {
            ...booking,
          };
        }),
      );
    }
  }, [props]);

  useEffect(() => {
    validationCheck(bookings);
  }, [bookings]);

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const requestBookings = bookings?.map(booking => {
      return {
        objectId: booking?.bookingId,
        process: `update-booking`,
        detailedBookingId: booking?.detailedBookingId,
        dateTimeOfService: {
          ...booking?.dateTimeOfService,
        },
        chauffer: {
          ...booking?.chauffer,
        },
        pickupLocation: booking?.pickupLocation,
        dropLocation: booking?.dropLocation,
        pickupDate: booking?.pickupDate ?? '',
        pickupTime: booking?.pickupTime ?? '',
        remarks: booking?.remarks ?? '',
        referenceNumber: booking?.referenceNumber ?? '',
        numberOfPassengers: booking?.numberOfPassengers ?? '0',
        numberOfBabySeats: booking?.numberOfBabySeats ?? '0',
        numberOfLuggages: booking?.numberOfLuggages ?? '0',
        vendor: {
          ...booking?.vendor,
          price: Number(booking?.vendor?.price ?? 0),
        },
      };
    });

    dispatch({
      type: UPDATE_BOOKING,
      payload: {
        data: {
          projectId: Constant?.CURRENT_SELECTED_PROGRAM?.id,
          bookingTypeId: Constant?.BOOKING_TYPES?.meetAndGreetBookings?.objectId,
          bookingsData: requestBookings,
        },
      },
    });
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const cancelHandler = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;
    let tempBookings = [...bookings];
    let selectedChangingBooking = tempBookings[index];
    let vendorPrice = '';
    let chaufferMobile = '';
    let tempCarNumber = '';
    let tempReferenceNo = '';
    let tempRemarks = '';
    let tempPickupLocation = '';

    switch (name) {
      case 'chauffer-name':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          chauffer: {
            ...selectedChangingBooking?.chauffer,
            name: isValidAplha(value) ? value : selectedChangingBooking?.chauffer?.name,
          },
        };
        break;

      case 'chauffer-mobile':
        chaufferMobile = value?.length === 0 ? '' : isANumber(value) ? value : selectedChangingBooking?.chauffer?.mobile;
        selectedChangingBooking = {
          ...selectedChangingBooking,
          chauffer: {
            ...selectedChangingBooking?.chauffer,
            mobile: chaufferMobile,
          },
        };
        break;

      case 'confirm-pickup-time':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          pickupTime: value,
        };
        break;

      case 'confirm-pickup-location':
        tempPickupLocation = value?.length === 0 ? '' : isValidAlphaNumberic(value) ? value : selectedChangingBooking?.pickupLocation?.address;
        selectedChangingBooking = {
          ...selectedChangingBooking,
          pickupLocation: {
            ...selectedChangingBooking?.pickupLocation,
            address: tempPickupLocation,
          },
        };
        break;

      case 'car-number':
        tempCarNumber = value?.length === 0 ? '' : isValidAlphaNumbericWithoutSpace(value) ? value : selectedChangingBooking?.chauffer?.carNumber;
        selectedChangingBooking = {
          ...selectedChangingBooking,
          chauffer: {
            ...selectedChangingBooking?.chauffer,
            carNumber: tempCarNumber,
          },
        };
        break;

      case 'vendor-car-type':
        selectedChangingBooking = {
          ...selectedChangingBooking,
          chauffer: {
            ...selectedChangingBooking?.chauffer,
            carType: value,
          },
          vendor: {
            ...selectedChangingBooking?.vendor,
            carType: value,
          },
        };
        break;

      case 'reference-no':
        tempReferenceNo = value?.length === 0 ? '' : isANumber(value) ? value : selectedChangingBooking?.referenceNumber;
        selectedChangingBooking = {
          ...selectedChangingBooking,
          referenceNumber: tempReferenceNo,
        };
        break;

      case 'vendor-price':
        vendorPrice = value?.length === 0 ? '' : isANumberWithDecimal(value) ? value : selectedChangingBooking?.vendor?.price;
        selectedChangingBooking = {
          ...selectedChangingBooking,
          vendor: {
            ...selectedChangingBooking?.vendor,
            price: vendorPrice,
          },
        };
        break;

      case 'vendor-remarks':
        tempRemarks = value?.length === 0 ? '' : isValidAlphaNumberic(value) ? value : selectedChangingBooking?.remarks;
        selectedChangingBooking = {
          ...selectedChangingBooking,
          remarks: tempRemarks,
        };
        break;

      default:
      // do nothing
    }

    tempBookings.splice(index, 1, selectedChangingBooking);
    setBookings(tempBookings);
    validationCheck(tempBookings);
  };

  /* ---------------------------- VALIDATION HANDLER ---------------------------- */
  const validationCheck = data => {
    let flag = false;
    for (let i = 0; i < data?.length; i++) {
      if (data[i]?.chauffer?.name === '') {
        flag = true;
        break;
      } else if (data[i]?.chauffer?.mobile === '') {
        flag = true;
        break;
      } else if (data[i]?.pickupLocation?.address === '') {
        flag = true;
        break;
      } else if (data[i]?.chauffer?.carNumber === '') {
        flag = true;
        break;
      } else if (data[i]?.vendor?.carType === '') {
        flag = true;
        break;
      } else if (data[i]?.referenceNumber === '') {
        flag = true;
        break;
      } else if (data[i]?.remarks === '') {
        flag = true;
        break;
      } else if (data[i]?.vendor?.price === 0 || data[i]?.vendor?.price === '') {
        flag = true;
        break;
      }
    }
    flag ? setIsSubmitBtnDisabled(true) : setIsSubmitBtnDisabled(false);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      scrollable={true}
      id="exampleModal"
      size="lg"
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title">Are you sure you want to go ahead?</h5>
        <Button
          type="button"
          onClick={() => {
            tog_varying1();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        {/* <button onClick={() => console.log({ bookings })}>get state</button> */}
        <form>
          <Row>
            <Col md={12}>
              <Table bordered responsive style={{ width: '100%' }} className="table-custom">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Booking ID</th>
                    <th className="w-100"> Name</th>
                    <th className="w-300">Chauffeur Name</th>
                    <th>Chauffeur Mobile No</th>
                    <th>Confirm Pickup Date</th>
                    <th> Confirm Pickup Time</th>
                    <th> Confirm Pickup Location</th>
                    <th> Car Number</th>
                    <th> Vendor Car Type</th>
                    <th> Confirmed No. Of Passengers</th>
                    <th> Confirmed No. Of Baby Seats</th>
                    <th> Confirmed No. Of Luggages</th>
                    <th> Reference No.</th>
                    <th> Admin Remarks For Confirmed</th>
                    <th> Vendor Price (AED)</th>
                  </tr>
                </thead>
                <tbody>
                  {bookings &&
                    bookings?.map((booking, index) => (
                      <tr key={index}>
                        <th>{index + 1}.</th>
                        <td>{booking?.bookingId}</td>
                        <td>{booking?.user?.name}</td>
                        <td>
                          <Input
                            id="chauffer-name"
                            name="chauffer-name"
                            type="text"
                            className="form-control"
                            style={{ width: '200px' }}
                            value={booking?.chauffer?.name}
                            onChange={e => inputChangeHandler(e, index)}
                            placeholder="Chauffeur Name"
                          />
                        </td>
                        <td>
                          <Input
                            id="chauffer-mobile"
                            name="chauffer-mobile"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.chauffer?.mobile}
                            onChange={e => inputChangeHandler(e, index)}
                            placeholder="Chauffeur Mobile"
                          />
                        </td>
                        <td>
                          <Flatpickr
                            className={`form-control overlay-disable`}
                            name="confirm-pickup-date"
                            id="confirm-pickup-date"
                            value={new Date(booking?.pickupDate)}
                            disabled={true}
                            options={{
                              defaultMinute: '00',
                              defaultHour: '00',
                              enableTime: false,
                              dateFormat: 'Y-m-d',
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            id="confirm-pickup-time"
                            name="confirm-pickup-time"
                            type="time"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.pickupTime}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            id="confirm-pickup-location"
                            name="confirm-pickup-location"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.pickupLocation?.address}
                            onChange={e => inputChangeHandler(e, index)}
                            placeholder="Confirm Pickup Location"
                          />
                        </td>
                        <td>
                          <Input
                            id="car-number"
                            name="car-number"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.chauffer?.carNumber}
                            onChange={e => inputChangeHandler(e, index)}
                            placeholder="Car Number"
                          />
                        </td>
                        <td>
                          <select
                            id="vendor-car-type"
                            style={{ width: '200px' }}
                            name="vendor-car-type"
                            className="form-select"
                            value={booking?.vendor?.carType}
                            onChange={e => inputChangeHandler(e, index)}
                          >
                            <option value="">Choose...</option>
                            <option value="Sedan">Sedan</option>
                            <option value="MVP">MVP</option>
                            <option value="Luxury">Luxury</option>
                          </select>
                        </td>
                        <td>
                          <Input
                            id="confirmed-of-passengers"
                            name="confirmed-of-passengers"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.numberOfPassengers}
                            // onChange={inputChangeHandler}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            id="confirmed-baby-seats"
                            name="confirmed-baby-seats"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.numberOfBabySeats}
                            // onChange={inputChangeHandler}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            id="confirmed-no-luggage"
                            name="confirmed-no-luggage"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.numberOfLuggages}
                            // onChange={inputChangeHandler}
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Input
                            id="reference-no"
                            name="reference-no"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.referenceNumber}
                            onChange={e => inputChangeHandler(e, index)}
                            placeholder="Reference No"
                          />
                        </td>
                        <td>
                          <Input
                            id="vendor-remarks"
                            name="vendor-remarks"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.remarks}
                            onChange={e => inputChangeHandler(e, index)}
                            placeholder="Admin Remarks For Confirmed"
                          />
                        </td>
                        <td>
                          <Input
                            id="vendor-price"
                            name="vendor-price"
                            type="text"
                            style={{ width: '200px' }}
                            className="form-control"
                            value={booking?.vendor?.price}
                            onChange={e => inputChangeHandler(e, index)}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <p className="text-muted m-0">Once you will click on proceed, you will not be able to change previous state.</p>
          </Row>
        </form>
      </ModalBody>

      <ModalFooter className="pb-0">
        <div className="modal-footer mx-auto">
          <Button className="btn btn-light overlay-disable" color="primary" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
            Sure
          </Button>
        </div>
      </ModalFooter>
      {/* <div className="modal-footer d-flex justify-content-center">
      <Button
        className="overlay-disable"
        color="primary"
        onClick={submitHandler}
        disabled={isSubmitBtnDisabled}
      >
        Submit
      </Button>
    </div> */}
    </Modal>
  );
};

export default UpdateModal;
